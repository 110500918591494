import React, { useMemo, useState } from "react";
import {
  GetAllSubscriptionPlansAsAdminDocument,
  useGetAllSubscriptionPlansAsAdminQuery,
  useUpdateSubscriptionPlanAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";
import { getDataByLanguage } from "../../common/utils/getDataByLanguage";
import Loading from "../../common/components/Loading";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Grid from "../../common/components/Grid";
import { Button, Card } from "../../common/styles/commonStyles";
import Input from "../../common/components/Input";

function SubscriptionPassContainer() {
  const [tabIndex, setTabIndex] = useState(0);

  const { loading, data } = useGetAllSubscriptionPlansAsAdminQuery({
    fetchPolicy: "cache-and-network",
  });

  const [updatePassAsAdmin] = useUpdateSubscriptionPlanAsAdminMutation();

  const showingData = useMemo(
    () => getDataByLanguage(data?.getAllSubscriptionPlansAsAdmin || []),
    [data?.getAllSubscriptionPlansAsAdmin]
  );

  function onTabSelect(index: number) {
    setTabIndex(index);
  }

  function onUpdate(typeInput: any) {
    updatePassAsAdmin({
      variables: {
        subscriptionPlanInput: typeInput,
      },
      refetchQueries: !typeInput?.id
        ? () => [
            {
              query: GetAllSubscriptionPlansAsAdminDocument,
            },
          ]
        : undefined,
    });
  }

  if (loading) return <Loading />;

  return (
    <Tabs tabIndex={tabIndex} onSelect={onTabSelect}>
      <TabList>
        {showingData &&
          Object.keys(showingData)?.map((key) => <Tab key={key}>{key}</Tab>)}
      </TabList>
      {showingData && !!Object.keys(showingData)?.length ? (
        Object.keys(showingData)?.map((key) => (
          <TabPanel key={key}>
            <Grid width="100%" gridTemplateColumns="repeat(4, 1fr)" gap={12}>
              <PassCard subscriptionPlan={null} updateFunction={onUpdate} />
              {showingData[key]?.map((subscriptionPlan) => (
                <PassCard
                  key={subscriptionPlan?.id}
                  subscriptionPlan={subscriptionPlan}
                  updateFunction={onUpdate}
                />
              ))}
            </Grid>
          </TabPanel>
        ))
      ) : (
        <TabPanel>
          <PassCard subscriptionPlan={null} updateFunction={onUpdate} />
        </TabPanel>
      )}
    </Tabs>
  );
}

export default SubscriptionPassContainer;

interface SubscriptionPlanCard {
  subscriptionPlan?: {
    __typename?: "SubscriptionPlan";
    id: string;
    language: string;
    name: string;
    summary?: string | null;
    description?: string | null;
    initialPrice: number;
    finalPrice: number;
    tickets: number;
    duration: number;
    freeTrial: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  } | null;

  updateFunction?: (typeInput: any) => void;
}

function PassCard({ subscriptionPlan, updateFunction }: SubscriptionPlanCard) {
  const [state, setState] = useState({
    id: subscriptionPlan?.id || "",
    language: subscriptionPlan?.language || "",
    name: subscriptionPlan?.name || "",
    summary: subscriptionPlan?.summary || "",
    description: subscriptionPlan?.description || "",
    initialPrice: subscriptionPlan?.initialPrice || 0,
    finalPrice: subscriptionPlan?.finalPrice || 0,
    tickets: subscriptionPlan?.tickets || 0,
    active: subscriptionPlan ? subscriptionPlan?.active : true,
  });

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value, checked } = e.target;

    if (
      (key === "tickets" || key === "initialPrice" || key === "finalPrice") &&
      isNaN(Number(value))
    ) {
      return;
    }

    setState((prev) => ({
      ...prev,
      [key]: key === "active" ? checked : value,
    }));
  }

  const {
    id,
    language,
    name,
    summary,
    description,
    initialPrice,
    finalPrice,
    tickets,
    active,
  } = state;

  return (
    <Card>
      {!!subscriptionPlan && <Input label="id" disabled value={id} />}
      <Input
        label="언어 코드"
        placeholder="데이터 언어 코드"
        value={language}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "language")
        }
      />
      <Input
        label="명칭"
        placeholder="명칭"
        value={name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "name")
        }
      />
      <Input
        label="간단 설명"
        placeholder="간단 설명"
        value={summary}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "summary")
        }
      />

      <Input
        label="상담건수"
        placeholder="상담건수"
        type="number"
        inputMode="numeric"
        value={tickets}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "tickets")
        }
      />

      <Input
        label="가격"
        placeholder="가격"
        type="number"
        inputMode="numeric"
        value={finalPrice}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "finalPrice")
        }
      />

      <Input
        label="공개여부"
        placeholder="공개여부"
        type="checkbox"
        checked={active}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "active")
        }
      />

      <Button
        disabled={!updateFunction}
        onClick={() => {
          if (!updateFunction) return;
          updateFunction({
            id,
            language,
            name,
            summary,
            description,
            tickets: Number(tickets),
            initialPrice: Number(finalPrice),
            finalPrice: Number(finalPrice),
            active,
            duration: 0,
            freeTrial: 0,
          });

          if (!subscriptionPlan) {
            setState({
              id: "",
              language: "",
              name: "",
              summary: "",
              description: "",
              initialPrice: 0,
              finalPrice: 0,
              tickets: 0,
              active: true,
            });
          }
        }}
      >
        {subscriptionPlan ? "업데이트" : "추가"}
      </Button>
    </Card>
  );
}
