import React from "react";
import { Section, SectionTitle } from "../../common/styles/commonStyles";

function MainContainer() {
  return (
    <Section>
      <SectionTitle>Medoc Admin</SectionTitle>
    </Section>
  );
}

export default MainContainer;
