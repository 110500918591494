import React, { useMemo, useState } from "react";
import { Section } from "../../common/styles/commonStyles";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import PHRAllergyTypes from "../components/PHRAllergyTypes";
import PHRAllergySymptoms from "../components/PHRAllergySymptoms";
import PHRChronicDiseases from "../components/PHRChronicDiseases";
import PHRVaccines from "../components/PHRVaccines";

function PHROptionsContainer() {
  const [tabIndex, setTabIndex] = useState(0);

  function onTabSelect(index: number) {
    setTabIndex(index);
  }

  return (
    <Section>
      <div style={{ marginBottom: 24 }}>
        <a
          href="https://en.wikipedia.org/wiki/IETF_language_tag"
          target="_blank"
        >
          언어 코드 리스트
        </a>
      </div>
      <Tabs tabIndex={tabIndex} onSelect={onTabSelect}>
        <TabList>
          <Tab>Allergy</Tab>
          <Tab>Allergy Symptom</Tab>
          <Tab>Chronic disease</Tab>
          <Tab>Vaccine</Tab>
        </TabList>
        <TabPanel>
          <PHRAllergyTypes />
        </TabPanel>
        <TabPanel>
          <PHRAllergySymptoms />
        </TabPanel>

        <TabPanel>
          <PHRChronicDiseases />
        </TabPanel>

        <TabPanel>
          <PHRVaccines />
        </TabPanel>
      </Tabs>
    </Section>
  );
}

export default PHROptionsContainer;
