import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  PhoneNumber: { input: any; output: any };
  Upload: { input: any; output: any };
};

export type AllergySymptom = {
  __typename?: "AllergySymptom";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  language?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type AllergyType = {
  __typename?: "AllergyType";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  language?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type BankAccount = {
  __typename?: "BankAccount";
  accountNumber: Scalars["String"]["output"];
  bankName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  user: User;
};

export type BankAccountInput = {
  accountNumber: Scalars["String"]["input"];
  bankName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Billing = {
  __typename?: "Billing";
  active: Scalars["Boolean"]["output"];
  cardName?: Maybe<Scalars["String"]["output"]>;
  cardNumber?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  customerUid: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  pgProvider?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type BillingInput = {
  customerUid: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum BloodType {
  A = "A",
  Ab = "AB",
  B = "B",
  O = "O",
}

export type ChronicDiseaseType = {
  __typename?: "ChronicDiseaseType";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  language?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type Education = {
  __typename?: "Education";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  major?: Maybe<Scalars["String"]["output"]>;
  school?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type EducationInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  major?: InputMaybe<Scalars["String"]["input"]>;
  school?: InputMaybe<Scalars["String"]["input"]>;
};

export type Experience = {
  __typename?: "Experience";
  createdAt: Scalars["DateTime"]["output"];
  duration?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  job?: Maybe<Scalars["String"]["output"]>;
  place?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ExperienceInput = {
  duration?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  job?: InputMaybe<Scalars["String"]["input"]>;
  place?: InputMaybe<Scalars["String"]["input"]>;
};

export type ExtraPlan = {
  __typename?: "ExtraPlan";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  finalPrice: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  initialPrice: Scalars["Float"]["output"];
  tickets: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type ExtraPlanInput = {
  active: Scalars["Boolean"]["input"];
  finalPrice: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  initialPrice: Scalars["Float"]["input"];
  tickets: Scalars["Int"]["input"];
};

export type Faq = {
  __typename?: "FAQ";
  active: Scalars["Boolean"]["output"];
  answer: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  order: Scalars["Int"]["output"];
  question: Scalars["String"]["output"];
};

export type FaqInput = {
  active: Scalars["Boolean"]["input"];
  answer: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  order: Scalars["Int"]["input"];
  question: Scalars["String"]["input"];
};

export type File = {
  __typename?: "File";
  encoding: Scalars["String"]["output"];
  filename: Scalars["String"]["output"];
  mimetype: Scalars["String"]["output"];
};

export enum Gender {
  Female = "FEMALE",
  Male = "MALE",
}

export type Instructor = {
  __typename?: "Instructor";
  createdAt: Scalars["DateTime"]["output"];
  educations?: Maybe<Array<Maybe<Education>>>;
  experiences?: Maybe<Array<Maybe<Experience>>>;
  id: Scalars["ID"]["output"];
  introduction?: Maybe<Scalars["String"]["output"]>;
  job?: Maybe<Scalars["String"]["output"]>;
  online: Scalars["Boolean"]["output"];
  qualificationNumber?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type InstructorConnection = {
  __typename?: "InstructorConnection";
  edges: Array<Maybe<Instructor>>;
  pageInfo: PageInfo;
};

export type InstructorInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  introduction?: InputMaybe<Scalars["String"]["input"]>;
  job?: InputMaybe<Scalars["String"]["input"]>;
  qualificationNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type Interpreter = {
  __typename?: "Interpreter";
  createdAt: Scalars["DateTime"]["output"];
  educations?: Maybe<Array<Maybe<Education>>>;
  experiences?: Maybe<Array<Maybe<Experience>>>;
  id: Scalars["ID"]["output"];
  introduction?: Maybe<Scalars["String"]["output"]>;
  online: Scalars["Boolean"]["output"];
  passportNumber?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  visaNumber?: Maybe<Scalars["String"]["output"]>;
};

export type InterpreterConnection = {
  __typename?: "InterpreterConnection";
  edges: Array<Maybe<Interpreter>>;
  pageInfo: PageInfo;
};

export type InterpreterInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  introduction?: InputMaybe<Scalars["String"]["input"]>;
  passportNumber?: InputMaybe<Scalars["String"]["input"]>;
  visaNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type Invitation = {
  __typename?: "Invitation";
  createdAt: Scalars["DateTime"]["output"];
  expiresAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  receiver: Instructor;
  sender: User;
  session?: Maybe<Session>;
  status: InvitationStatus;
  updatedAt: Scalars["DateTime"]["output"];
  userPass?: Maybe<UserPass>;
  userSubscription?: Maybe<UserSubscription>;
};

export enum InvitationStatus {
  Accepted = "ACCEPTED",
  Cancelled = "CANCELLED",
  Declined = "DECLINED",
  Pending = "PENDING",
}

export type LanguageOption = {
  __typename?: "LanguageOption";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  language: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type LanguageOptionInput = {
  active: Scalars["Boolean"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  language: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  order: Scalars["Int"]["input"];
};

export type Management = {
  __typename?: "Management";
  address?: Maybe<Scalars["String"]["output"]>;
  ceoName?: Maybe<Scalars["String"]["output"]>;
  companyName?: Maybe<Scalars["String"]["output"]>;
  copyright?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  escroRegistration?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  kakaoAddress?: Maybe<Scalars["String"]["output"]>;
  paymentInformation?: Maybe<Scalars["String"]["output"]>;
  paymentWarning?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  registrationNumber?: Maybe<Scalars["String"]["output"]>;
  sessionDuration: Scalars["Int"]["output"];
  shopAddress?: Maybe<Scalars["String"]["output"]>;
};

export type ManagementInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  ceoName?: InputMaybe<Scalars["String"]["input"]>;
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  copyright?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  escroRegistration?: InputMaybe<Scalars["String"]["input"]>;
  kakaoAddress?: InputMaybe<Scalars["String"]["input"]>;
  paymentInformation?: InputMaybe<Scalars["String"]["input"]>;
  paymentWarning?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  registrationNumber?: InputMaybe<Scalars["String"]["input"]>;
  sessionDuration: Scalars["Int"]["input"];
  shopAddress?: InputMaybe<Scalars["String"]["input"]>;
};

export type Media = {
  __typename?: "Media";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  size: Scalars["Int"]["output"];
  /** This is provided only when thumbnailFile is uploaded */
  thumbnailUri?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
  uri: Scalars["String"]["output"];
};

export type MediaInput = {
  file: Scalars["Upload"]["input"];
  thumbnailFile?: InputMaybe<Scalars["Upload"]["input"]>;
};

export type Message = {
  __typename?: "Message";
  body?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  media?: Maybe<Media>;
  session: Session;
  user: User;
};

export type MessageConnection = {
  __typename?: "MessageConnection";
  edges?: Maybe<Array<Maybe<Message>>>;
  pageInfo: PageInfo;
};

export type Mutation = {
  __typename?: "Mutation";
  _empty?: Maybe<Scalars["Boolean"]["output"]>;
  addPaymentsAsAdmin: Scalars["Boolean"]["output"];
  addSubscription: UserSubscription;
  cancelMyPendingInvitation: Invitation;
  changeActiveBilling: Billing;
  changeBillingKeyForSubscription: UserSubscription;
  deleteMyAllergy: Scalars["Boolean"]["output"];
  deleteMyChronicDisease: Scalars["Boolean"]["output"];
  deleteMyInstructorEducation: Array<Maybe<Education>>;
  deleteMyInstructorExperience: Array<Maybe<Experience>>;
  deleteMyInsurance: Scalars["Boolean"]["output"];
  deleteMyInterpreterEducation: Array<Maybe<Education>>;
  deleteMyInterpreterExperience: Array<Maybe<Experience>>;
  deleteMyMedicine: Scalars["Boolean"]["output"];
  deleteMySurgery: Scalars["Boolean"]["output"];
  deleteMyVaccine: Scalars["Boolean"]["output"];
  extendSession: SessionExtension;
  joinSession: Session;
  joinSessionAsInterpreter: Session;
  leaveSession: Session;
  purchaseExtraPlanByBilling: Scalars["Boolean"]["output"];
  purchaseUserPassByBilling: Scalars["Boolean"]["output"];
  receiveUserPass?: Maybe<UserPass>;
  requestCancelSubscription: UserSubscription;
  respondToInvitation: Invitation;
  revokeSubscription: UserSubscription;
  sendInvitation: Invitation;
  /**
   * Sends a new message. Publishes updateChat and newMessage.
   * When sending text, attachment is not required.
   * When sending media, text is not required.
   */
  sendMessage: Message;
  sendUserPass: UserPass;
  toggleInterpreterNeeded: Session;
  toggleMyOnlineStatusAsInstructor: Instructor;
  toggleMyOnlineStatusAsInterpreter: Instructor;
  toggleSessionStatusAsInstructor: Session;
  updateAllergySymptomAsAdmin: AllergySymptom;
  updateAllergyTypeAsAdmin: AllergyType;
  updateChronicDiseaseTypeAsAdmin: ChronicDiseaseType;
  updateExtraPlanAsAdmin: ExtraPlan;
  updateFAQAsAdmin: Faq;
  updateLanguageOptionAsAdmin: LanguageOption;
  updateManagementAsAdmin: Management;
  updateMyAllergy: UserAllergy;
  updateMyBankAccount: BankAccount;
  updateMyChronicDisease: UserChronicDisease;
  updateMyInstructor: Instructor;
  updateMyInstructorEducations: Array<Maybe<Education>>;
  updateMyInstructorExperiences: Array<Maybe<Experience>>;
  updateMyInsurance: UserInsurance;
  updateMyInterpreter: Interpreter;
  updateMyInterpreterEducations: Array<Maybe<Education>>;
  updateMyInterpreterExperiences: Array<Maybe<Experience>>;
  updateMyMedicine: UserMedicine;
  updateMyProfile: Profile;
  updateMySurgery: UserSurgery;
  updateMyUserProfile: User;
  updateMyVaccine: UserVaccine;
  updatePassAsAdmin: Pass;
  updatePaymentUnitPriceAsAdmin: PaymentUnitPrice;
  updateSessionReportAsInstructor: Session;
  updateSubscriptionPlanAsAdmin: SubscriptionPlan;
  /** Update user's customerUid which matches billing key for subscription payment. */
  updateUserBilling: Array<Maybe<Billing>>;
  updateVaccineTypeAsAdmin: VaccineType;
};

export type MutationAddPaymentsAsAdminArgs = {
  paymentInput: PaymentInput;
};

export type MutationAddSubscriptionArgs = {
  billingId: Scalars["ID"]["input"];
  finalPrice: Scalars["Float"]["input"];
  subscriptionPlanId: Scalars["ID"]["input"];
};

export type MutationCancelMyPendingInvitationArgs = {
  invitationId: Scalars["ID"]["input"];
};

export type MutationChangeActiveBillingArgs = {
  billingId: Scalars["ID"]["input"];
};

export type MutationChangeBillingKeyForSubscriptionArgs = {
  newBillingId: Scalars["ID"]["input"];
  subscriptionId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationDeleteMyAllergyArgs = {
  userAllergyId: Scalars["ID"]["input"];
};

export type MutationDeleteMyChronicDiseaseArgs = {
  userChronicDiseaseId: Scalars["ID"]["input"];
};

export type MutationDeleteMyInstructorEducationArgs = {
  educationId: Scalars["ID"]["input"];
};

export type MutationDeleteMyInstructorExperienceArgs = {
  experienceId: Scalars["ID"]["input"];
};

export type MutationDeleteMyInsuranceArgs = {
  userInsuranceId: Scalars["ID"]["input"];
};

export type MutationDeleteMyInterpreterEducationArgs = {
  educationId: Scalars["ID"]["input"];
};

export type MutationDeleteMyInterpreterExperienceArgs = {
  experienceId: Scalars["ID"]["input"];
};

export type MutationDeleteMyMedicineArgs = {
  userMedicineId: Scalars["ID"]["input"];
};

export type MutationDeleteMySurgeryArgs = {
  userSurgeryId: Scalars["ID"]["input"];
};

export type MutationDeleteMyVaccineArgs = {
  userVaccineId: Scalars["ID"]["input"];
};

export type MutationExtendSessionArgs = {
  sessionId: Scalars["ID"]["input"];
  subscriptionId?: InputMaybe<Scalars["ID"]["input"]>;
  userPassId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationJoinSessionArgs = {
  sessionId: Scalars["ID"]["input"];
};

export type MutationJoinSessionAsInterpreterArgs = {
  sessionId: Scalars["ID"]["input"];
};

export type MutationLeaveSessionArgs = {
  sessionId: Scalars["ID"]["input"];
};

export type MutationPurchaseExtraPlanByBillingArgs = {
  billingId: Scalars["ID"]["input"];
  extraPlanId: Scalars["ID"]["input"];
  userExtraPlanId: Scalars["ID"]["input"];
};

export type MutationPurchaseUserPassByBillingArgs = {
  billingId: Scalars["ID"]["input"];
  passId: Scalars["ID"]["input"];
  userPassId: Scalars["ID"]["input"];
};

export type MutationReceiveUserPassArgs = {
  code: Scalars["String"]["input"];
};

export type MutationRequestCancelSubscriptionArgs = {
  subscriptionId: Scalars["ID"]["input"];
};

export type MutationRespondToInvitationArgs = {
  invitationId: Scalars["ID"]["input"];
  status: InvitationStatus;
};

export type MutationRevokeSubscriptionArgs = {
  newBillingId: Scalars["ID"]["input"];
  subscriptionId: Scalars["ID"]["input"];
};

export type MutationSendInvitationArgs = {
  receiverId: Scalars["ID"]["input"];
  userPassId?: InputMaybe<Scalars["ID"]["input"]>;
  userSubscriptionId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationSendMessageArgs = {
  attachment?: InputMaybe<MediaInput>;
  body?: InputMaybe<Scalars["String"]["input"]>;
  sessionId: Scalars["ID"]["input"];
};

export type MutationSendUserPassArgs = {
  name: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
  userPassId: Scalars["ID"]["input"];
};

export type MutationToggleInterpreterNeededArgs = {
  needInterpreter: Scalars["Boolean"]["input"];
  sessionId: Scalars["ID"]["input"];
};

export type MutationToggleMyOnlineStatusAsInstructorArgs = {
  status: Scalars["Boolean"]["input"];
};

export type MutationToggleMyOnlineStatusAsInterpreterArgs = {
  status: Scalars["Boolean"]["input"];
};

export type MutationToggleSessionStatusAsInstructorArgs = {
  sessionId: Scalars["ID"]["input"];
  status: SessionStatus;
};

export type MutationUpdateAllergySymptomAsAdminArgs = {
  typeInput?: InputMaybe<PhrTypeInput>;
};

export type MutationUpdateAllergyTypeAsAdminArgs = {
  typeInput?: InputMaybe<PhrTypeInput>;
};

export type MutationUpdateChronicDiseaseTypeAsAdminArgs = {
  typeInput?: InputMaybe<PhrTypeInput>;
};

export type MutationUpdateExtraPlanAsAdminArgs = {
  extraPlanInput: ExtraPlanInput;
};

export type MutationUpdateFaqAsAdminArgs = {
  faqInput: FaqInput;
};

export type MutationUpdateLanguageOptionAsAdminArgs = {
  languageOptionInput: LanguageOptionInput;
};

export type MutationUpdateManagementAsAdminArgs = {
  managementInput: ManagementInput;
};

export type MutationUpdateMyAllergyArgs = {
  userAllergyInput: UserAllergyInput;
};

export type MutationUpdateMyBankAccountArgs = {
  bankAccountInput: BankAccountInput;
};

export type MutationUpdateMyChronicDiseaseArgs = {
  userChronicDiseaseInput: UserChronicDiseaseInput;
};

export type MutationUpdateMyInstructorArgs = {
  instructorInput: InstructorInput;
};

export type MutationUpdateMyInstructorEducationsArgs = {
  educationInputs: Array<InputMaybe<EducationInput>>;
};

export type MutationUpdateMyInstructorExperiencesArgs = {
  experienceInputs: Array<InputMaybe<ExperienceInput>>;
};

export type MutationUpdateMyInsuranceArgs = {
  userInsuranceInput: UserInsuranceInput;
};

export type MutationUpdateMyInterpreterArgs = {
  interpreterInput: InterpreterInput;
};

export type MutationUpdateMyInterpreterEducationsArgs = {
  educationInputs: Array<InputMaybe<EducationInput>>;
};

export type MutationUpdateMyInterpreterExperiencesArgs = {
  experienceInputs: Array<InputMaybe<ExperienceInput>>;
};

export type MutationUpdateMyMedicineArgs = {
  userMedicineInput: UserMedicineInput;
};

export type MutationUpdateMyProfileArgs = {
  profileInput: ProfileInput;
};

export type MutationUpdateMySurgeryArgs = {
  userSurgeryInput: UserSurgeryInput;
};

export type MutationUpdateMyUserProfileArgs = {
  userInput: UserInput;
};

export type MutationUpdateMyVaccineArgs = {
  userVaccineInput: UserVaccineInput;
};

export type MutationUpdatePassAsAdminArgs = {
  passInput: PassInput;
};

export type MutationUpdatePaymentUnitPriceAsAdminArgs = {
  paymentUnitPriceInput: PaymentUnitPriceInput;
};

export type MutationUpdateSessionReportAsInstructorArgs = {
  report: Scalars["String"]["input"];
  sessionId: Scalars["ID"]["input"];
};

export type MutationUpdateSubscriptionPlanAsAdminArgs = {
  subscriptionPlanInput: SubscriptionPlanInput;
};

export type MutationUpdateUserBillingArgs = {
  billingInput: BillingInput;
};

export type MutationUpdateVaccineTypeAsAdminArgs = {
  typeInput?: InputMaybe<PhrTypeInput>;
};

export type Nationality = {
  __typename?: "Nationality";
  emoji?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  unicode: Scalars["String"]["output"];
};

export type PhrTypeInput = {
  active: Scalars["Boolean"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  order: Scalars["Int"]["input"];
};

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["String"]["output"]>;
  hasNextPage: Scalars["Boolean"]["output"];
};

export type PaginationInput = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first: Scalars["Int"]["input"];
};

export type Pass = {
  __typename?: "Pass";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  finalPrice: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  initialPrice: Scalars["Float"]["output"];
  language: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  summary?: Maybe<Scalars["String"]["output"]>;
  tickets: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type PassInput = {
  active: Scalars["Boolean"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  finalPrice: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  initialPrice: Scalars["Float"]["input"];
  language: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  summary?: InputMaybe<Scalars["String"]["input"]>;
  tickets: Scalars["Int"]["input"];
};

export type Payment = {
  __typename?: "Payment";
  amount: Scalars["Float"]["output"];
  bankAccount: BankAccount;
  createdAt: Scalars["DateTime"]["output"];
  currency: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  session?: Maybe<Session>;
  user: User;
};

export type PaymentConnection = {
  __typename?: "PaymentConnection";
  edges: Array<Maybe<Payment>>;
  pageInfo: PageInfo;
};

export type PaymentInput = {
  amount: Scalars["Float"]["input"];
  bankAccountId: Scalars["ID"]["input"];
  currency: Scalars["String"]["input"];
  sessionIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  type: PaymentUnitPriceType;
  userId: Scalars["ID"]["input"];
};

export type PaymentUnitPrice = {
  __typename?: "PaymentUnitPrice";
  currency: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  language: Scalars["String"]["output"];
  type: PaymentUnitPriceType;
  unitPrice: Scalars["Float"]["output"];
};

export type PaymentUnitPriceInput = {
  currency: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  language: Scalars["String"]["input"];
  type: PaymentUnitPriceType;
  unitPrice: Scalars["Float"]["input"];
};

export enum PaymentUnitPriceType {
  Instructor = "INSTRUCTOR",
  Interpreter = "INTERPRETER",
}

export type Profile = {
  __typename?: "Profile";
  bloodType?: Maybe<BloodType>;
  companyName?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  emergencyCallerAddress?: Maybe<Scalars["String"]["output"]>;
  emergencyCallerBloodType?: Maybe<BloodType>;
  emergencyCallerName?: Maybe<Scalars["String"]["output"]>;
  emergencyCallerNationality?: Maybe<Nationality>;
  emergencyCallerPhone?: Maybe<Scalars["String"]["output"]>;
  emergencyCallerPhoneCode?: Maybe<Scalars["String"]["output"]>;
  emergencyCallerRelation?: Maybe<Scalars["String"]["output"]>;
  height?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["ID"]["output"];
  occupation?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  weight?: Maybe<Scalars["Float"]["output"]>;
};

export type ProfileInput = {
  bloodType?: InputMaybe<BloodType>;
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  emergencyCallerAddress?: InputMaybe<Scalars["String"]["input"]>;
  emergencyCallerBloodType?: InputMaybe<BloodType>;
  emergencyCallerName?: InputMaybe<Scalars["String"]["input"]>;
  emergencyCallerNationalityId?: InputMaybe<Scalars["ID"]["input"]>;
  emergencyCallerPhone?: InputMaybe<Scalars["String"]["input"]>;
  emergencyCallerPhoneCode?: InputMaybe<Scalars["String"]["input"]>;
  emergencyCallerRelation?: InputMaybe<Scalars["String"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  occupation?: InputMaybe<Scalars["String"]["input"]>;
  weight?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Query = {
  __typename?: "Query";
  _empty?: Maybe<Scalars["Boolean"]["output"]>;
  getActiveSessions: SessionConnection;
  getAllExtraPlansAsAdmin: Array<Maybe<ExtraPlan>>;
  getAllInstructors: Array<Maybe<Instructor>>;
  getAllInstructorsAsAdmin: InstructorConnection;
  getAllInterpretersAsAdmin: InterpreterConnection;
  getAllPassesAsAdmin: Array<Maybe<Pass>>;
  getAllSubscriptionPlansAsAdmin: Array<Maybe<SubscriptionPlan>>;
  getAllergySymptoms?: Maybe<Array<Maybe<AllergySymptom>>>;
  getAllergySymptomsAsAdmin?: Maybe<Array<Maybe<AllergySymptom>>>;
  getAllergyTypes?: Maybe<Array<Maybe<AllergyType>>>;
  getAllergyTypesAsAdmin?: Maybe<Array<Maybe<AllergyType>>>;
  getChronicDiseaseTypes?: Maybe<Array<Maybe<ChronicDiseaseType>>>;
  getChronicDiseaseTypesAsAdmin?: Maybe<Array<Maybe<ChronicDiseaseType>>>;
  getExtraPlans: Array<Maybe<ExtraPlan>>;
  getFAQs?: Maybe<Array<Maybe<Faq>>>;
  getInstructor: Instructor;
  getInterpreter: Interpreter;
  getInterpreterUnpaidSessionAsAdmin?: Maybe<Array<Maybe<Session>>>;
  getInvitation?: Maybe<Invitation>;
  getLanguageOptions?: Maybe<Array<Maybe<LanguageOption>>>;
  getManagement: Management;
  getMyActiveSession?: Maybe<Session>;
  getMyAllergies: Array<Maybe<UserAllergy>>;
  getMyBankAccount?: Maybe<BankAccount>;
  getMyBillings?: Maybe<Array<Maybe<Billing>>>;
  getMyChronicDiseases: Array<Maybe<UserChronicDisease>>;
  getMyInstructor: Instructor;
  getMyInstructorEducations: Array<Maybe<Education>>;
  getMyInstructorExperiences: Array<Maybe<Experience>>;
  getMyInsurances: Array<Maybe<UserInsurance>>;
  getMyInterpreter: Interpreter;
  getMyInterpreterEducations: Array<Maybe<Education>>;
  getMyInterpreterExperiences: Array<Maybe<Experience>>;
  getMyMedicines: Array<Maybe<UserMedicine>>;
  getMyOwnUserPasses: UserPassConnection;
  getMyPaymentHistory: PaymentConnection;
  getMyPendingInvitations: Array<Maybe<Invitation>>;
  getMyProfile: Profile;
  getMyPurchasedUserPasses: UserPassConnection;
  getMySessions: SessionConnection;
  getMySubscriptions: Array<Maybe<UserSubscription>>;
  getMySurgerys: Array<Maybe<UserSurgery>>;
  getMyUserProfile: User;
  getMyVaccines: Array<Maybe<UserVaccine>>;
  getNationalities: Array<Maybe<Nationality>>;
  getOfflineInstructors: Array<Maybe<Instructor>>;
  getOnlineInstructors: Array<Maybe<Instructor>>;
  getOnlineInterpreters: Array<Maybe<Interpreter>>;
  getPass: Pass;
  getPassesByLanguage: Array<Maybe<Pass>>;
  getPaymentUnitPricesAsAdmin?: Maybe<Array<Maybe<PaymentUnitPrice>>>;
  getSession: Session;
  /**
   * Get a list of messages in a given chat. Publishes updateChat to notify users
   * that lastSeenAt timestamp is updated.
   *
   * All notifications attached to this chat will be marked as checked.
   *
   * Supports pagination.
   */
  getSessionMessages: MessageConnection;
  getSubscriptionPlan: SubscriptionPlan;
  getSubscriptionPlansByLanguage: Array<Maybe<SubscriptionPlan>>;
  getUnpaidSessionsAsAdmin?: Maybe<Array<Maybe<Session>>>;
  getUser: User;
  getUserAllergies: Array<Maybe<UserAllergy>>;
  getUserBankAccountAsAdmin?: Maybe<BankAccount>;
  getUserChronicDiseases: Array<Maybe<UserChronicDisease>>;
  getUserExtraPlan: UserExtraPlan;
  getUserExtraPlansAsAdmin: UserExtraPlanConnection;
  getUserInsurances: Array<Maybe<UserInsurance>>;
  getUserMedicines: Array<Maybe<UserMedicine>>;
  getUserPass: UserPass;
  getUserPassesAsAdmin: UserPassConnection;
  getUserSubscriptionsAsAdmin: UserSubscriptionConnection;
  getUserSurgerys: Array<Maybe<UserSurgery>>;
  getUserVaccines: Array<Maybe<UserVaccine>>;
  getUsersAsAdmin: UserConnection;
  getVaccineTypes?: Maybe<Array<Maybe<VaccineType>>>;
  getVaccineTypesAsAdmin?: Maybe<Array<Maybe<ChronicDiseaseType>>>;
  searchNationality: Array<Maybe<Nationality>>;
};

export type QueryGetActiveSessionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetAllInstructorsAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetAllInterpretersAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetAllergySymptomsArgs = {
  activeOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetAllergyTypesArgs = {
  activeOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetChronicDiseaseTypesArgs = {
  activeOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetFaQsArgs = {
  activeOnly: Scalars["Boolean"]["input"];
};

export type QueryGetInstructorArgs = {
  instructorId: Scalars["ID"]["input"];
};

export type QueryGetInterpreterArgs = {
  interpreterId: Scalars["ID"]["input"];
};

export type QueryGetInvitationArgs = {
  invitationId: Scalars["ID"]["input"];
};

export type QueryGetLanguageOptionsArgs = {
  language?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetMyOwnUserPassesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<Array<InputMaybe<UserPassStatus>>>;
};

export type QueryGetMyPaymentHistoryArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  type: PaymentUnitPriceType;
};

export type QueryGetMyPurchasedUserPassesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetMySessionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetPassArgs = {
  passId: Scalars["ID"]["input"];
};

export type QueryGetPassesByLanguageArgs = {
  language: Scalars["String"]["input"];
};

export type QueryGetSessionArgs = {
  sessionId: Scalars["ID"]["input"];
};

export type QueryGetSessionMessagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sessionId: Scalars["ID"]["input"];
};

export type QueryGetSubscriptionPlanArgs = {
  planId: Scalars["ID"]["input"];
};

export type QueryGetSubscriptionPlansByLanguageArgs = {
  language: Scalars["String"]["input"];
};

export type QueryGetUserArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserAllergiesArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserBankAccountAsAdminArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserChronicDiseasesArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserExtraPlanArgs = {
  userExtraPlanId: Scalars["ID"]["input"];
};

export type QueryGetUserExtraPlansAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetUserInsurancesArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserMedicinesArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserPassArgs = {
  userPassId: Scalars["ID"]["input"];
};

export type QueryGetUserPassesAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetUserSubscriptionsAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetUserSurgerysArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserVaccinesArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUsersAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetVaccineTypesArgs = {
  activeOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySearchNationalityArgs = {
  query: Scalars["String"]["input"];
};

export type Session = {
  __typename?: "Session";
  createdAt: Scalars["DateTime"]["output"];
  endAt?: Maybe<Scalars["DateTime"]["output"]>;
  extensions?: Maybe<Array<Maybe<SessionExtension>>>;
  id: Scalars["ID"]["output"];
  instructor?: Maybe<Instructor>;
  interpreter?: Maybe<Interpreter>;
  /** If access token returns null, the access to this room cannot be granted */
  jwt?: Maybe<Scalars["String"]["output"]>;
  needInterpreter?: Maybe<Scalars["Boolean"]["output"]>;
  password?: Maybe<Scalars["String"]["output"]>;
  report?: Maybe<Scalars["String"]["output"]>;
  requester: User;
  startAt?: Maybe<Scalars["DateTime"]["output"]>;
  status?: Maybe<SessionStatus>;
  updatedAt: Scalars["DateTime"]["output"];
  userPass?: Maybe<UserPass>;
  userSubscription?: Maybe<UserSubscription>;
  users?: Maybe<Array<Maybe<SessionUser>>>;
};

export type SessionConnection = {
  __typename?: "SessionConnection";
  edges?: Maybe<Array<Maybe<Session>>>;
  pageInfo: PageInfo;
};

export type SessionExtension = {
  __typename?: "SessionExtension";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  session: Session;
  userPass?: Maybe<UserPass>;
  userSubscription?: Maybe<UserSubscription>;
};

export enum SessionStatus {
  Done = "DONE",
  Pending = "PENDING",
  Started = "STARTED",
}

export type SessionUser = {
  __typename?: "SessionUser";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  joinAt: Scalars["DateTime"]["output"];
  leaveAt?: Maybe<Scalars["DateTime"]["output"]>;
  session: Session;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type Subscription = {
  __typename?: "Subscription";
  _empty?: Maybe<Scalars["String"]["output"]>;
  invitationUpdates: Invitation;
  /** Published whenever a new message is sent in a chat. */
  newMessage?: Maybe<Message>;
  sessionUpdate: Session;
  updatedOnlineInstructor: Instructor;
  updatedOnlineInterpreter: Interpreter;
};

export type SubscriptionNewMessageArgs = {
  sessionId: Scalars["ID"]["input"];
};

export type SubscriptionSessionUpdateArgs = {
  sessionId: Scalars["ID"]["input"];
};

export type SubscriptionPlan = {
  __typename?: "SubscriptionPlan";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  duration: Scalars["Int"]["output"];
  finalPrice: Scalars["Float"]["output"];
  freeTrial: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  initialPrice: Scalars["Float"]["output"];
  language: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  summary?: Maybe<Scalars["String"]["output"]>;
  tickets: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type SubscriptionPlanInput = {
  active: Scalars["Boolean"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  duration: Scalars["Int"]["input"];
  finalPrice: Scalars["Float"]["input"];
  freeTrial: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  initialPrice: Scalars["Float"]["input"];
  language: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  summary?: InputMaybe<Scalars["String"]["input"]>;
  tickets: Scalars["Int"]["input"];
};

export enum SubscriptionStatus {
  Active = "ACTIVE",
  Cancelled = "CANCELLED",
  CancelRequested = "CANCEL_REQUESTED",
  Expired = "EXPIRED",
  Paused = "PAUSED",
}

export type User = {
  __typename?: "User";
  avatar?: Maybe<Media>;
  billings?: Maybe<Array<Maybe<Billing>>>;
  createdAt: Scalars["DateTime"]["output"];
  dateOfBirth?: Maybe<Scalars["Date"]["output"]>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Gender>;
  id: Scalars["ID"]["output"];
  instructor?: Maybe<Instructor>;
  interpreter?: Maybe<Interpreter>;
  isSubscriber?: Maybe<Scalars["Boolean"]["output"]>;
  languageOptions?: Maybe<Array<Maybe<LanguageOption>>>;
  name?: Maybe<Scalars["String"]["output"]>;
  nationality?: Maybe<Nationality>;
  phone?: Maybe<Scalars["String"]["output"]>;
  phoneCode?: Maybe<Scalars["String"]["output"]>;
  profile?: Maybe<Profile>;
  provider?: Maybe<Scalars["String"]["output"]>;
  resignReason?: Maybe<Scalars["String"]["output"]>;
  type: UserType;
  updatedAt: Scalars["DateTime"]["output"];
  usuableTickets: Scalars["Int"]["output"];
};

export type UserAllergy = {
  __typename?: "UserAllergy";
  createdAt: Scalars["DateTime"]["output"];
  firstAid?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  summary?: Maybe<Scalars["String"]["output"]>;
  symptoms?: Maybe<Array<Maybe<AllergySymptom>>>;
  type: AllergyType;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type UserAllergyInput = {
  firstAid?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  symptomIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  typeId: Scalars["ID"]["input"];
};

export type UserChronicDisease = {
  __typename?: "UserChronicDisease";
  createdAt: Scalars["DateTime"]["output"];
  etc?: Maybe<Scalars["String"]["output"]>;
  firstAid?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  medicine?: Maybe<Scalars["String"]["output"]>;
  type: ChronicDiseaseType;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type UserChronicDiseaseInput = {
  etc?: InputMaybe<Scalars["String"]["input"]>;
  firstAid?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  medicine?: InputMaybe<Scalars["String"]["input"]>;
  typeId: Scalars["ID"]["input"];
};

export type UserConnection = {
  __typename?: "UserConnection";
  edges?: Maybe<Array<Maybe<User>>>;
  pageInfo: PageInfo;
};

export type UserExtraPlan = {
  __typename?: "UserExtraPlan";
  createdAt: Scalars["DateTime"]["output"];
  extraPlan: ExtraPlan;
  id: Scalars["ID"]["output"];
  purchaser: User;
  status: UserExtraPlanStatus;
  updatedAt: Scalars["DateTime"]["output"];
};

export type UserExtraPlanConnection = {
  __typename?: "UserExtraPlanConnection";
  edges?: Maybe<Array<Maybe<UserExtraPlan>>>;
  pageInfo: PageInfo;
};

export enum UserExtraPlanStatus {
  Cancelled = "CANCELLED",
  Success = "SUCCESS",
}

export type UserInput = {
  avatarInput?: InputMaybe<MediaInput>;
  dateOfBirth?: InputMaybe<Scalars["Date"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  languageOptionIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nationalityId?: InputMaybe<Scalars["ID"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  phoneCode?: InputMaybe<Scalars["String"]["input"]>;
  type: UserType;
};

export type UserInsurance = {
  __typename?: "UserInsurance";
  companyNumber?: Maybe<Scalars["String"]["output"]>;
  coverage?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  groupId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  memberId?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type UserInsuranceInput = {
  companyNumber?: InputMaybe<Scalars["String"]["input"]>;
  coverage?: InputMaybe<Scalars["String"]["input"]>;
  groupId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  memberId?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserMedicine = {
  __typename?: "UserMedicine";
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  dosage?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  medicine?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type UserMedicineInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  dosage?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  medicine?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserPass = {
  __typename?: "UserPass";
  code: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  pass: Pass;
  purchaser: User;
  sent: Scalars["Boolean"]["output"];
  status: UserPassStatus;
  updatedAt: Scalars["DateTime"]["output"];
  user?: Maybe<User>;
};

export type UserPassConnection = {
  __typename?: "UserPassConnection";
  edges?: Maybe<Array<Maybe<UserPass>>>;
  pageInfo: PageInfo;
};

export enum UserPassStatus {
  Cancelled = "CANCELLED",
  Purchased = "PURCHASED",
  Received = "RECEIVED",
  Used = "USED",
}

export type UserSubscription = {
  __typename?: "UserSubscription";
  billing?: Maybe<Billing>;
  createdAt: Scalars["DateTime"]["output"];
  from: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  nextPayAt?: Maybe<Scalars["DateTime"]["output"]>;
  status: SubscriptionStatus;
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  to?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type UserSubscriptionConnection = {
  __typename?: "UserSubscriptionConnection";
  edges?: Maybe<Array<Maybe<UserSubscription>>>;
  pageInfo: PageInfo;
};

export type UserSurgery = {
  __typename?: "UserSurgery";
  createdAt: Scalars["DateTime"]["output"];
  department?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  media?: Maybe<Media>;
  part?: Maybe<Scalars["String"]["output"]>;
  surgery?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type UserSurgeryInput = {
  department?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  mediaInput?: InputMaybe<MediaInput>;
  part?: InputMaybe<Scalars["String"]["input"]>;
  surgery?: InputMaybe<Scalars["String"]["input"]>;
};

export enum UserType {
  Instructor = "INSTRUCTOR",
  Interpreter = "INTERPRETER",
  Personal = "PERSONAL",
}

export type UserVaccine = {
  __typename?: "UserVaccine";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  lastVaccinatedAt?: Maybe<Scalars["String"]["output"]>;
  level?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  vaccineType: VaccineType;
};

export type UserVaccineInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  lastVaccinatedAt?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  vaccineTypeId: Scalars["ID"]["input"];
};

export type VaccineType = {
  __typename?: "VaccineType";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  language?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type UpdateFaqAsAdminMutationVariables = Exact<{
  faqInput: FaqInput;
}>;

export type UpdateFaqAsAdminMutation = {
  __typename?: "Mutation";
  updateFAQAsAdmin: {
    __typename?: "FAQ";
    id: string;
    question: string;
    answer: string;
    active: boolean;
    order: number;
  };
};

export type GetFaQsQueryVariables = Exact<{
  activeOnly: Scalars["Boolean"]["input"];
}>;

export type GetFaQsQuery = {
  __typename?: "Query";
  getFAQs?: Array<{
    __typename?: "FAQ";
    id: string;
    question: string;
    answer: string;
    active: boolean;
    order: number;
  } | null> | null;
};

export type GetAllPassesAsAdminQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPassesAsAdminQuery = {
  __typename?: "Query";
  getAllPassesAsAdmin: Array<{
    __typename?: "Pass";
    id: string;
    language: string;
    name: string;
    summary?: string | null;
    description?: string | null;
    initialPrice: number;
    finalPrice: number;
    tickets: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  } | null>;
};

export type GetAllSubscriptionPlansAsAdminQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllSubscriptionPlansAsAdminQuery = {
  __typename?: "Query";
  getAllSubscriptionPlansAsAdmin: Array<{
    __typename?: "SubscriptionPlan";
    id: string;
    language: string;
    name: string;
    summary?: string | null;
    description?: string | null;
    initialPrice: number;
    finalPrice: number;
    tickets: number;
    duration: number;
    freeTrial: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  } | null>;
};

export type GetAllExtraPlansAsAdminQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllExtraPlansAsAdminQuery = {
  __typename?: "Query";
  getAllExtraPlansAsAdmin: Array<{
    __typename?: "ExtraPlan";
    id: string;
    tickets: number;
    initialPrice: number;
    finalPrice: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  } | null>;
};

export type UpdatePassAsAdminMutationVariables = Exact<{
  passInput: PassInput;
}>;

export type UpdatePassAsAdminMutation = {
  __typename?: "Mutation";
  updatePassAsAdmin: {
    __typename?: "Pass";
    id: string;
    language: string;
    name: string;
    summary?: string | null;
    description?: string | null;
    initialPrice: number;
    finalPrice: number;
    tickets: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  };
};

export type UpdateSubscriptionPlanAsAdminMutationVariables = Exact<{
  subscriptionPlanInput: SubscriptionPlanInput;
}>;

export type UpdateSubscriptionPlanAsAdminMutation = {
  __typename?: "Mutation";
  updateSubscriptionPlanAsAdmin: {
    __typename?: "SubscriptionPlan";
    id: string;
    language: string;
    name: string;
    summary?: string | null;
    description?: string | null;
    initialPrice: number;
    finalPrice: number;
    tickets: number;
    duration: number;
    freeTrial: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  };
};

export type UpdateExtraPlanAsAdminMutationVariables = Exact<{
  extraPlanInput: ExtraPlanInput;
}>;

export type UpdateExtraPlanAsAdminMutation = {
  __typename?: "Mutation";
  updateExtraPlanAsAdmin: {
    __typename?: "ExtraPlan";
    id: string;
    tickets: number;
    initialPrice: number;
    finalPrice: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  };
};

export type GetLanguageOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetLanguageOptionsQuery = {
  __typename?: "Query";
  getLanguageOptions?: Array<{
    __typename?: "LanguageOption";
    id: string;
    language: string;
    name: string;
    active: boolean;
    order: number;
    createdAt: any;
    updatedAt: any;
  } | null> | null;
};

export type UpdateLanguageOptionAsAdminMutationVariables = Exact<{
  languageOptionInput: LanguageOptionInput;
}>;

export type UpdateLanguageOptionAsAdminMutation = {
  __typename?: "Mutation";
  updateLanguageOptionAsAdmin: {
    __typename?: "LanguageOption";
    id: string;
    language: string;
    name: string;
    active: boolean;
    order: number;
    createdAt: any;
    updatedAt: any;
  };
};

export type GetManagementQueryVariables = Exact<{ [key: string]: never }>;

export type GetManagementQuery = {
  __typename?: "Query";
  getManagement: {
    __typename?: "Management";
    id: string;
    companyName?: string | null;
    address?: string | null;
    copyright?: string | null;
    email?: string | null;
    ceoName?: string | null;
    registrationNumber?: string | null;
    escroRegistration?: string | null;
    phone?: string | null;
    kakaoAddress?: string | null;
    sessionDuration: number;
    shopAddress?: string | null;
    paymentWarning?: string | null;
    paymentInformation?: string | null;
  };
};

export type UpdateManagementAsAdminMutationVariables = Exact<{
  managementInput: ManagementInput;
}>;

export type UpdateManagementAsAdminMutation = {
  __typename?: "Mutation";
  updateManagementAsAdmin: {
    __typename?: "Management";
    id: string;
    companyName?: string | null;
    address?: string | null;
    copyright?: string | null;
    email?: string | null;
    ceoName?: string | null;
    registrationNumber?: string | null;
    escroRegistration?: string | null;
    phone?: string | null;
    kakaoAddress?: string | null;
    sessionDuration: number;
    shopAddress?: string | null;
    paymentWarning?: string | null;
    paymentInformation?: string | null;
  };
};

export type GetPaymentUnitPricesAsAdminQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPaymentUnitPricesAsAdminQuery = {
  __typename?: "Query";
  getPaymentUnitPricesAsAdmin?: Array<{
    __typename?: "PaymentUnitPrice";
    id: string;
    language: string;
    unitPrice: number;
    currency: string;
    type: PaymentUnitPriceType;
  } | null> | null;
};

export type UpdatePaymentUnitPriceAsAdminMutationVariables = Exact<{
  paymentUnitPriceInput: PaymentUnitPriceInput;
}>;

export type UpdatePaymentUnitPriceAsAdminMutation = {
  __typename?: "Mutation";
  updatePaymentUnitPriceAsAdmin: {
    __typename?: "PaymentUnitPrice";
    id: string;
    language: string;
    unitPrice: number;
    currency: string;
    type: PaymentUnitPriceType;
  };
};

export type GetUnpaidSessionsAsAdminQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUnpaidSessionsAsAdminQuery = {
  __typename?: "Query";
  getUnpaidSessionsAsAdmin?: Array<{
    __typename?: "Session";
    id: string;
    startAt?: any | null;
    endAt?: any | null;
    report?: string | null;
    needInterpreter?: boolean | null;
    status?: SessionStatus | null;
    createdAt: any;
    updatedAt: any;
    requester: { __typename?: "User"; id: string; name?: string | null };
    instructor?: {
      __typename?: "Instructor";
      id: string;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    } | null;
    interpreter?: {
      __typename?: "Interpreter";
      id: string;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    } | null;
    userPass?: {
      __typename?: "UserPass";
      id: string;
      pass: { __typename?: "Pass"; id: string; name: string };
    } | null;
    userSubscription?: { __typename?: "UserSubscription"; id: string } | null;
    users?: Array<{
      __typename?: "SessionUser";
      id: string;
      joinAt: any;
      leaveAt?: any | null;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    } | null> | null;
  } | null> | null;
};

export type GetInterpreterUnpaidSessionAsAdminQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetInterpreterUnpaidSessionAsAdminQuery = {
  __typename?: "Query";
  getInterpreterUnpaidSessionAsAdmin?: Array<{
    __typename?: "Session";
    id: string;
    startAt?: any | null;
    endAt?: any | null;
    report?: string | null;
    needInterpreter?: boolean | null;
    status?: SessionStatus | null;
    createdAt: any;
    updatedAt: any;
    requester: { __typename?: "User"; id: string; name?: string | null };
    instructor?: {
      __typename?: "Instructor";
      id: string;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    } | null;
    interpreter?: {
      __typename?: "Interpreter";
      id: string;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    } | null;
    userPass?: {
      __typename?: "UserPass";
      id: string;
      pass: { __typename?: "Pass"; id: string; name: string };
    } | null;
    userSubscription?: { __typename?: "UserSubscription"; id: string } | null;
    users?: Array<{
      __typename?: "SessionUser";
      id: string;
      joinAt: any;
      leaveAt?: any | null;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    } | null> | null;
  } | null> | null;
};

export type AddPaymentsAsAdminMutationVariables = Exact<{
  paymentInput: PaymentInput;
}>;

export type AddPaymentsAsAdminMutation = {
  __typename?: "Mutation";
  addPaymentsAsAdmin: boolean;
};

export type GetUserBankAccountAsAdminQueryVariables = Exact<{
  userId: Scalars["ID"]["input"];
}>;

export type GetUserBankAccountAsAdminQuery = {
  __typename?: "Query";
  getUserBankAccountAsAdmin?: {
    __typename?: "BankAccount";
    id: string;
    bankName: string;
    accountNumber: string;
    user: { __typename?: "User"; id: string };
  } | null;
};

export type GetAllergyTypesAsAdminQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllergyTypesAsAdminQuery = {
  __typename?: "Query";
  getAllergyTypesAsAdmin?: Array<{
    __typename?: "AllergyType";
    id: string;
    language?: string | null;
    name: string;
    order: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  } | null> | null;
};

export type GetAllergySymptomsAsAdminQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllergySymptomsAsAdminQuery = {
  __typename?: "Query";
  getAllergySymptomsAsAdmin?: Array<{
    __typename?: "AllergySymptom";
    id: string;
    language?: string | null;
    name: string;
    order: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  } | null> | null;
};

export type GetChronicDiseaseTypesAsAdminQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetChronicDiseaseTypesAsAdminQuery = {
  __typename?: "Query";
  getChronicDiseaseTypesAsAdmin?: Array<{
    __typename?: "ChronicDiseaseType";
    id: string;
    language?: string | null;
    name: string;
    order: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  } | null> | null;
};

export type GetVaccineTypesAsAdminQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetVaccineTypesAsAdminQuery = {
  __typename?: "Query";
  getVaccineTypesAsAdmin?: Array<{
    __typename?: "ChronicDiseaseType";
    id: string;
    language?: string | null;
    name: string;
    order: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  } | null> | null;
};

export type UpdateChronicDiseaseTypeAsAdminMutationVariables = Exact<{
  typeInput?: InputMaybe<PhrTypeInput>;
}>;

export type UpdateChronicDiseaseTypeAsAdminMutation = {
  __typename?: "Mutation";
  updateChronicDiseaseTypeAsAdmin: {
    __typename?: "ChronicDiseaseType";
    id: string;
    language?: string | null;
    name: string;
    order: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  };
};

export type UpdateAllergyTypeAsAdminMutationVariables = Exact<{
  typeInput?: InputMaybe<PhrTypeInput>;
}>;

export type UpdateAllergyTypeAsAdminMutation = {
  __typename?: "Mutation";
  updateAllergyTypeAsAdmin: {
    __typename?: "AllergyType";
    id: string;
    language?: string | null;
    name: string;
    order: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  };
};

export type UpdateAllergySymptomAsAdminMutationVariables = Exact<{
  typeInput?: InputMaybe<PhrTypeInput>;
}>;

export type UpdateAllergySymptomAsAdminMutation = {
  __typename?: "Mutation";
  updateAllergySymptomAsAdmin: {
    __typename?: "AllergySymptom";
    id: string;
    language?: string | null;
    name: string;
    order: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  };
};

export type UpdateVaccineTypeAsAdminMutationVariables = Exact<{
  typeInput?: InputMaybe<PhrTypeInput>;
}>;

export type UpdateVaccineTypeAsAdminMutation = {
  __typename?: "Mutation";
  updateVaccineTypeAsAdmin: {
    __typename?: "VaccineType";
    id: string;
    language?: string | null;
    name: string;
    order: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  };
};

export type GetUserQueryVariables = Exact<{
  userId: Scalars["ID"]["input"];
}>;

export type GetUserQuery = {
  __typename?: "Query";
  getUser: {
    __typename?: "User";
    id: string;
    type: UserType;
    email?: string | null;
    name?: string | null;
    gender?: Gender | null;
    phoneCode?: string | null;
    phone?: string | null;
    dateOfBirth?: any | null;
    provider?: string | null;
    resignReason?: string | null;
    isSubscriber?: boolean | null;
    usuableTickets: number;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    avatar?: { __typename?: "Media"; id: string; uri: string } | null;
    nationality?: {
      __typename?: "Nationality";
      id: string;
      name: string;
    } | null;
    profile?: {
      __typename?: "Profile";
      id: string;
      weight?: number | null;
      height?: number | null;
      bloodType?: BloodType | null;
      occupation?: string | null;
      companyName?: string | null;
      emergencyCallerName?: string | null;
      emergencyCallerPhoneCode?: string | null;
      emergencyCallerPhone?: string | null;
      emergencyCallerBloodType?: BloodType | null;
      emergencyCallerRelation?: string | null;
      emergencyCallerAddress?: string | null;
      createdAt: any;
      updatedAt: any;
      user: { __typename?: "User"; id: string };
      emergencyCallerNationality?: {
        __typename?: "Nationality";
        id: string;
        name: string;
      } | null;
    } | null;
    instructor?: {
      __typename?: "Instructor";
      id: string;
      introduction?: string | null;
      job?: string | null;
      qualificationNumber?: string | null;
      online: boolean;
      createdAt: any;
      updatedAt: any;
      user: { __typename?: "User"; id: string };
      educations?: Array<{
        __typename?: "Education";
        id: string;
        school?: string | null;
        major?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
      experiences?: Array<{
        __typename?: "Experience";
        id: string;
        place?: string | null;
        job?: string | null;
        duration?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null;
    interpreter?: {
      __typename?: "Interpreter";
      id: string;
      introduction?: string | null;
      visaNumber?: string | null;
      passportNumber?: string | null;
      online: boolean;
      createdAt: any;
      updatedAt: any;
      user: { __typename?: "User"; id: string };
      educations?: Array<{
        __typename?: "Education";
        id: string;
        school?: string | null;
        major?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
      experiences?: Array<{
        __typename?: "Experience";
        id: string;
        place?: string | null;
        job?: string | null;
        duration?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null;
    languageOptions?: Array<{
      __typename?: "LanguageOption";
      id: string;
      language: string;
      name: string;
      active: boolean;
    } | null> | null;
  };
};

export type GetUsersAsAdminQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetUsersAsAdminQuery = {
  __typename?: "Query";
  getUsersAsAdmin: {
    __typename?: "UserConnection";
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      endCursor?: string | null;
    };
    edges?: Array<{
      __typename?: "User";
      id: string;
      type: UserType;
      email?: string | null;
      name?: string | null;
      gender?: Gender | null;
      phoneCode?: string | null;
      phone?: string | null;
      dateOfBirth?: any | null;
      provider?: string | null;
      resignReason?: string | null;
      isSubscriber?: boolean | null;
      usuableTickets: number;
      createdAt: any;
      updatedAt: any;
      deletedAt?: any | null;
      avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      nationality?: {
        __typename?: "Nationality";
        id: string;
        name: string;
      } | null;
      profile?: { __typename?: "Profile"; id: string } | null;
      instructor?: { __typename?: "Instructor"; id: string } | null;
      interpreter?: { __typename?: "Interpreter"; id: string } | null;
      languageOptions?: Array<{
        __typename?: "LanguageOption";
        id: string;
        name: string;
      } | null> | null;
    } | null> | null;
  };
};

export type GetAllInstructorsAsAdminQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetAllInstructorsAsAdminQuery = {
  __typename?: "Query";
  getAllInstructorsAsAdmin: {
    __typename?: "InstructorConnection";
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      endCursor?: string | null;
    };
    edges: Array<{
      __typename?: "Instructor";
      id: string;
      introduction?: string | null;
      job?: string | null;
      qualificationNumber?: string | null;
      online: boolean;
      createdAt: any;
      updatedAt: any;
      user: {
        __typename?: "User";
        id: string;
        type: UserType;
        email?: string | null;
        name?: string | null;
        gender?: Gender | null;
        phoneCode?: string | null;
        phone?: string | null;
        dateOfBirth?: any | null;
        provider?: string | null;
        resignReason?: string | null;
        isSubscriber?: boolean | null;
        usuableTickets: number;
        createdAt: any;
        updatedAt: any;
        deletedAt?: any | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
        nationality?: {
          __typename?: "Nationality";
          id: string;
          name: string;
        } | null;
        profile?: { __typename?: "Profile"; id: string } | null;
        instructor?: { __typename?: "Instructor"; id: string } | null;
        interpreter?: { __typename?: "Interpreter"; id: string } | null;
        languageOptions?: Array<{
          __typename?: "LanguageOption";
          id: string;
          name: string;
        } | null> | null;
      };
      educations?: Array<{
        __typename?: "Education";
        id: string;
        school?: string | null;
        major?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
      experiences?: Array<{
        __typename?: "Experience";
        id: string;
        place?: string | null;
        job?: string | null;
        duration?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null>;
  };
};

export type GetAllInterpretersAsAdminQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetAllInterpretersAsAdminQuery = {
  __typename?: "Query";
  getAllInterpretersAsAdmin: {
    __typename?: "InterpreterConnection";
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      endCursor?: string | null;
    };
    edges: Array<{
      __typename?: "Interpreter";
      id: string;
      introduction?: string | null;
      visaNumber?: string | null;
      passportNumber?: string | null;
      online: boolean;
      createdAt: any;
      updatedAt: any;
      user: {
        __typename?: "User";
        id: string;
        type: UserType;
        email?: string | null;
        name?: string | null;
        gender?: Gender | null;
        phoneCode?: string | null;
        phone?: string | null;
        dateOfBirth?: any | null;
        provider?: string | null;
        resignReason?: string | null;
        isSubscriber?: boolean | null;
        usuableTickets: number;
        createdAt: any;
        updatedAt: any;
        deletedAt?: any | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
        nationality?: {
          __typename?: "Nationality";
          id: string;
          name: string;
        } | null;
        profile?: { __typename?: "Profile"; id: string } | null;
        instructor?: { __typename?: "Instructor"; id: string } | null;
        interpreter?: { __typename?: "Interpreter"; id: string } | null;
        languageOptions?: Array<{
          __typename?: "LanguageOption";
          id: string;
          name: string;
        } | null> | null;
      };
      educations?: Array<{
        __typename?: "Education";
        id: string;
        school?: string | null;
        major?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
      experiences?: Array<{
        __typename?: "Experience";
        id: string;
        place?: string | null;
        job?: string | null;
        duration?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null>;
  };
};

export type GetUserAllergiesQueryVariables = Exact<{
  userId: Scalars["ID"]["input"];
}>;

export type GetUserAllergiesQuery = {
  __typename?: "Query";
  getUserAllergies: Array<{
    __typename?: "UserAllergy";
    id: string;
    summary?: string | null;
    firstAid?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    type: {
      __typename?: "AllergyType";
      id: string;
      language?: string | null;
      name: string;
    };
    symptoms?: Array<{
      __typename?: "AllergySymptom";
      id: string;
      language?: string | null;
      name: string;
    } | null> | null;
  } | null>;
};

export type GetUserChronicDiseasesQueryVariables = Exact<{
  userId: Scalars["ID"]["input"];
}>;

export type GetUserChronicDiseasesQuery = {
  __typename?: "Query";
  getUserChronicDiseases: Array<{
    __typename?: "UserChronicDisease";
    id: string;
    etc?: string | null;
    medicine?: string | null;
    firstAid?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    type: {
      __typename?: "ChronicDiseaseType";
      id: string;
      language?: string | null;
      name: string;
    };
  } | null>;
};

export type GetUserMedicinesQueryVariables = Exact<{
  userId: Scalars["ID"]["input"];
}>;

export type GetUserMedicinesQuery = {
  __typename?: "Query";
  getUserMedicines: Array<{
    __typename?: "UserMedicine";
    id: string;
    medicine?: string | null;
    dosage?: string | null;
    description?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
  } | null>;
};

export type GetUserSurgerysQueryVariables = Exact<{
  userId: Scalars["ID"]["input"];
}>;

export type GetUserSurgerysQuery = {
  __typename?: "Query";
  getUserSurgerys: Array<{
    __typename?: "UserSurgery";
    id: string;
    surgery?: string | null;
    part?: string | null;
    department?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    media?: {
      __typename?: "Media";
      id: string;
      uri: string;
      name: string;
    } | null;
  } | null>;
};

export type GetUserVaccinesQueryVariables = Exact<{
  userId: Scalars["ID"]["input"];
}>;

export type GetUserVaccinesQuery = {
  __typename?: "Query";
  getUserVaccines: Array<{
    __typename?: "UserVaccine";
    id: string;
    level?: string | null;
    lastVaccinatedAt?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    vaccineType: {
      __typename?: "VaccineType";
      id: string;
      language?: string | null;
      name: string;
    };
  } | null>;
};

export type GetUserInsurancesQueryVariables = Exact<{
  userId: Scalars["ID"]["input"];
}>;

export type GetUserInsurancesQuery = {
  __typename?: "Query";
  getUserInsurances: Array<{
    __typename?: "UserInsurance";
    id: string;
    type?: string | null;
    name?: string | null;
    coverage?: string | null;
    memberId?: string | null;
    groupId?: string | null;
    companyNumber?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
  } | null>;
};

export type GetUserSubscriptionsAsAdminQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetUserSubscriptionsAsAdminQuery = {
  __typename?: "Query";
  getUserSubscriptionsAsAdmin: {
    __typename?: "UserSubscriptionConnection";
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      endCursor?: string | null;
    };
    edges?: Array<{
      __typename?: "UserSubscription";
      id: string;
      from: any;
      to?: any | null;
      nextPayAt?: any | null;
      status: SubscriptionStatus;
      createdAt: any;
      updatedAt: any;
      user: {
        __typename?: "User";
        id: string;
        email?: string | null;
        name?: string | null;
        gender?: Gender | null;
        phoneCode?: string | null;
        phone?: string | null;
        isSubscriber?: boolean | null;
        nationality?: {
          __typename?: "Nationality";
          id: string;
          name: string;
        } | null;
        languageOptions?: Array<{
          __typename?: "LanguageOption";
          id: string;
          name: string;
        } | null> | null;
      };
      subscriptionPlan?: {
        __typename?: "SubscriptionPlan";
        id: string;
        name: string;
        finalPrice: number;
        tickets: number;
      } | null;
    } | null> | null;
  };
};

export type GetUserPassesAsAdminQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetUserPassesAsAdminQuery = {
  __typename?: "Query";
  getUserPassesAsAdmin: {
    __typename?: "UserPassConnection";
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      endCursor?: string | null;
    };
    edges?: Array<{
      __typename?: "UserPass";
      id: string;
      status: UserPassStatus;
      createdAt: any;
      updatedAt: any;
      purchaser: {
        __typename?: "User";
        id: string;
        email?: string | null;
        name?: string | null;
        gender?: Gender | null;
        phoneCode?: string | null;
        phone?: string | null;
        isSubscriber?: boolean | null;
        nationality?: {
          __typename?: "Nationality";
          id: string;
          name: string;
        } | null;
        languageOptions?: Array<{
          __typename?: "LanguageOption";
          id: string;
          name: string;
        } | null> | null;
      };
      user?: {
        __typename?: "User";
        id: string;
        email?: string | null;
        name?: string | null;
        gender?: Gender | null;
        phoneCode?: string | null;
        phone?: string | null;
        isSubscriber?: boolean | null;
        nationality?: {
          __typename?: "Nationality";
          id: string;
          name: string;
        } | null;
        languageOptions?: Array<{
          __typename?: "LanguageOption";
          id: string;
          name: string;
        } | null> | null;
      } | null;
      pass: {
        __typename?: "Pass";
        id: string;
        name: string;
        finalPrice: number;
        tickets: number;
      };
    } | null> | null;
  };
};

export type GetUserExtraPlansAsAdminQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetUserExtraPlansAsAdminQuery = {
  __typename?: "Query";
  getUserExtraPlansAsAdmin: {
    __typename?: "UserExtraPlanConnection";
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      endCursor?: string | null;
    };
    edges?: Array<{
      __typename?: "UserExtraPlan";
      id: string;
      status: UserExtraPlanStatus;
      createdAt: any;
      updatedAt: any;
      purchaser: {
        __typename?: "User";
        id: string;
        email?: string | null;
        name?: string | null;
        gender?: Gender | null;
        phoneCode?: string | null;
        phone?: string | null;
        isSubscriber?: boolean | null;
        nationality?: {
          __typename?: "Nationality";
          id: string;
          name: string;
        } | null;
        languageOptions?: Array<{
          __typename?: "LanguageOption";
          id: string;
          name: string;
        } | null> | null;
      };
      extraPlan: {
        __typename?: "ExtraPlan";
        id: string;
        tickets: number;
        finalPrice: number;
      };
    } | null> | null;
  };
};

export const UpdateFaqAsAdminDocument = gql`
  mutation UpdateFAQAsAdmin($faqInput: FAQInput!) {
    updateFAQAsAdmin(faqInput: $faqInput) {
      id
      question
      answer
      active
      order
    }
  }
`;
export type UpdateFaqAsAdminMutationFn = Apollo.MutationFunction<
  UpdateFaqAsAdminMutation,
  UpdateFaqAsAdminMutationVariables
>;

/**
 * __useUpdateFaqAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateFaqAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFaqAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFaqAsAdminMutation, { data, loading, error }] = useUpdateFaqAsAdminMutation({
 *   variables: {
 *      faqInput: // value for 'faqInput'
 *   },
 * });
 */
export function useUpdateFaqAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFaqAsAdminMutation,
    UpdateFaqAsAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFaqAsAdminMutation,
    UpdateFaqAsAdminMutationVariables
  >(UpdateFaqAsAdminDocument, options);
}
export type UpdateFaqAsAdminMutationHookResult = ReturnType<
  typeof useUpdateFaqAsAdminMutation
>;
export type UpdateFaqAsAdminMutationResult =
  Apollo.MutationResult<UpdateFaqAsAdminMutation>;
export type UpdateFaqAsAdminMutationOptions = Apollo.BaseMutationOptions<
  UpdateFaqAsAdminMutation,
  UpdateFaqAsAdminMutationVariables
>;
export const GetFaQsDocument = gql`
  query GetFAQs($activeOnly: Boolean!) {
    getFAQs(activeOnly: $activeOnly) {
      id
      question
      answer
      active
      order
    }
  }
`;

/**
 * __useGetFaQsQuery__
 *
 * To run a query within a React component, call `useGetFaQsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFaQsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFaQsQuery({
 *   variables: {
 *      activeOnly: // value for 'activeOnly'
 *   },
 * });
 */
export function useGetFaQsQuery(
  baseOptions: Apollo.QueryHookOptions<GetFaQsQuery, GetFaQsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFaQsQuery, GetFaQsQueryVariables>(
    GetFaQsDocument,
    options
  );
}
export function useGetFaQsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFaQsQuery, GetFaQsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFaQsQuery, GetFaQsQueryVariables>(
    GetFaQsDocument,
    options
  );
}
export function useGetFaQsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFaQsQuery,
    GetFaQsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFaQsQuery, GetFaQsQueryVariables>(
    GetFaQsDocument,
    options
  );
}
export type GetFaQsQueryHookResult = ReturnType<typeof useGetFaQsQuery>;
export type GetFaQsLazyQueryHookResult = ReturnType<typeof useGetFaQsLazyQuery>;
export type GetFaQsSuspenseQueryHookResult = ReturnType<
  typeof useGetFaQsSuspenseQuery
>;
export type GetFaQsQueryResult = Apollo.QueryResult<
  GetFaQsQuery,
  GetFaQsQueryVariables
>;
export const GetAllPassesAsAdminDocument = gql`
  query GetAllPassesAsAdmin {
    getAllPassesAsAdmin {
      id
      language
      name
      summary
      description
      initialPrice
      finalPrice
      tickets
      active
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetAllPassesAsAdminQuery__
 *
 * To run a query within a React component, call `useGetAllPassesAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPassesAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPassesAsAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPassesAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllPassesAsAdminQuery,
    GetAllPassesAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllPassesAsAdminQuery,
    GetAllPassesAsAdminQueryVariables
  >(GetAllPassesAsAdminDocument, options);
}
export function useGetAllPassesAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPassesAsAdminQuery,
    GetAllPassesAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllPassesAsAdminQuery,
    GetAllPassesAsAdminQueryVariables
  >(GetAllPassesAsAdminDocument, options);
}
export function useGetAllPassesAsAdminSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAllPassesAsAdminQuery,
    GetAllPassesAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAllPassesAsAdminQuery,
    GetAllPassesAsAdminQueryVariables
  >(GetAllPassesAsAdminDocument, options);
}
export type GetAllPassesAsAdminQueryHookResult = ReturnType<
  typeof useGetAllPassesAsAdminQuery
>;
export type GetAllPassesAsAdminLazyQueryHookResult = ReturnType<
  typeof useGetAllPassesAsAdminLazyQuery
>;
export type GetAllPassesAsAdminSuspenseQueryHookResult = ReturnType<
  typeof useGetAllPassesAsAdminSuspenseQuery
>;
export type GetAllPassesAsAdminQueryResult = Apollo.QueryResult<
  GetAllPassesAsAdminQuery,
  GetAllPassesAsAdminQueryVariables
>;
export const GetAllSubscriptionPlansAsAdminDocument = gql`
  query GetAllSubscriptionPlansAsAdmin {
    getAllSubscriptionPlansAsAdmin {
      id
      language
      name
      summary
      description
      initialPrice
      finalPrice
      tickets
      duration
      freeTrial
      active
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetAllSubscriptionPlansAsAdminQuery__
 *
 * To run a query within a React component, call `useGetAllSubscriptionPlansAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSubscriptionPlansAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSubscriptionPlansAsAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSubscriptionPlansAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllSubscriptionPlansAsAdminQuery,
    GetAllSubscriptionPlansAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllSubscriptionPlansAsAdminQuery,
    GetAllSubscriptionPlansAsAdminQueryVariables
  >(GetAllSubscriptionPlansAsAdminDocument, options);
}
export function useGetAllSubscriptionPlansAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllSubscriptionPlansAsAdminQuery,
    GetAllSubscriptionPlansAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllSubscriptionPlansAsAdminQuery,
    GetAllSubscriptionPlansAsAdminQueryVariables
  >(GetAllSubscriptionPlansAsAdminDocument, options);
}
export function useGetAllSubscriptionPlansAsAdminSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAllSubscriptionPlansAsAdminQuery,
    GetAllSubscriptionPlansAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAllSubscriptionPlansAsAdminQuery,
    GetAllSubscriptionPlansAsAdminQueryVariables
  >(GetAllSubscriptionPlansAsAdminDocument, options);
}
export type GetAllSubscriptionPlansAsAdminQueryHookResult = ReturnType<
  typeof useGetAllSubscriptionPlansAsAdminQuery
>;
export type GetAllSubscriptionPlansAsAdminLazyQueryHookResult = ReturnType<
  typeof useGetAllSubscriptionPlansAsAdminLazyQuery
>;
export type GetAllSubscriptionPlansAsAdminSuspenseQueryHookResult = ReturnType<
  typeof useGetAllSubscriptionPlansAsAdminSuspenseQuery
>;
export type GetAllSubscriptionPlansAsAdminQueryResult = Apollo.QueryResult<
  GetAllSubscriptionPlansAsAdminQuery,
  GetAllSubscriptionPlansAsAdminQueryVariables
>;
export const GetAllExtraPlansAsAdminDocument = gql`
  query GetAllExtraPlansAsAdmin {
    getAllExtraPlansAsAdmin {
      id
      tickets
      initialPrice
      finalPrice
      active
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetAllExtraPlansAsAdminQuery__
 *
 * To run a query within a React component, call `useGetAllExtraPlansAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllExtraPlansAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllExtraPlansAsAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllExtraPlansAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllExtraPlansAsAdminQuery,
    GetAllExtraPlansAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllExtraPlansAsAdminQuery,
    GetAllExtraPlansAsAdminQueryVariables
  >(GetAllExtraPlansAsAdminDocument, options);
}
export function useGetAllExtraPlansAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllExtraPlansAsAdminQuery,
    GetAllExtraPlansAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllExtraPlansAsAdminQuery,
    GetAllExtraPlansAsAdminQueryVariables
  >(GetAllExtraPlansAsAdminDocument, options);
}
export function useGetAllExtraPlansAsAdminSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAllExtraPlansAsAdminQuery,
    GetAllExtraPlansAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAllExtraPlansAsAdminQuery,
    GetAllExtraPlansAsAdminQueryVariables
  >(GetAllExtraPlansAsAdminDocument, options);
}
export type GetAllExtraPlansAsAdminQueryHookResult = ReturnType<
  typeof useGetAllExtraPlansAsAdminQuery
>;
export type GetAllExtraPlansAsAdminLazyQueryHookResult = ReturnType<
  typeof useGetAllExtraPlansAsAdminLazyQuery
>;
export type GetAllExtraPlansAsAdminSuspenseQueryHookResult = ReturnType<
  typeof useGetAllExtraPlansAsAdminSuspenseQuery
>;
export type GetAllExtraPlansAsAdminQueryResult = Apollo.QueryResult<
  GetAllExtraPlansAsAdminQuery,
  GetAllExtraPlansAsAdminQueryVariables
>;
export const UpdatePassAsAdminDocument = gql`
  mutation UpdatePassAsAdmin($passInput: PassInput!) {
    updatePassAsAdmin(passInput: $passInput) {
      id
      language
      name
      summary
      description
      initialPrice
      finalPrice
      tickets
      active
      createdAt
      updatedAt
    }
  }
`;
export type UpdatePassAsAdminMutationFn = Apollo.MutationFunction<
  UpdatePassAsAdminMutation,
  UpdatePassAsAdminMutationVariables
>;

/**
 * __useUpdatePassAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdatePassAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePassAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePassAsAdminMutation, { data, loading, error }] = useUpdatePassAsAdminMutation({
 *   variables: {
 *      passInput: // value for 'passInput'
 *   },
 * });
 */
export function useUpdatePassAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePassAsAdminMutation,
    UpdatePassAsAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePassAsAdminMutation,
    UpdatePassAsAdminMutationVariables
  >(UpdatePassAsAdminDocument, options);
}
export type UpdatePassAsAdminMutationHookResult = ReturnType<
  typeof useUpdatePassAsAdminMutation
>;
export type UpdatePassAsAdminMutationResult =
  Apollo.MutationResult<UpdatePassAsAdminMutation>;
export type UpdatePassAsAdminMutationOptions = Apollo.BaseMutationOptions<
  UpdatePassAsAdminMutation,
  UpdatePassAsAdminMutationVariables
>;
export const UpdateSubscriptionPlanAsAdminDocument = gql`
  mutation UpdateSubscriptionPlanAsAdmin(
    $subscriptionPlanInput: SubscriptionPlanInput!
  ) {
    updateSubscriptionPlanAsAdmin(
      subscriptionPlanInput: $subscriptionPlanInput
    ) {
      id
      language
      name
      summary
      description
      initialPrice
      finalPrice
      tickets
      duration
      freeTrial
      active
      createdAt
      updatedAt
    }
  }
`;
export type UpdateSubscriptionPlanAsAdminMutationFn = Apollo.MutationFunction<
  UpdateSubscriptionPlanAsAdminMutation,
  UpdateSubscriptionPlanAsAdminMutationVariables
>;

/**
 * __useUpdateSubscriptionPlanAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionPlanAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionPlanAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionPlanAsAdminMutation, { data, loading, error }] = useUpdateSubscriptionPlanAsAdminMutation({
 *   variables: {
 *      subscriptionPlanInput: // value for 'subscriptionPlanInput'
 *   },
 * });
 */
export function useUpdateSubscriptionPlanAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSubscriptionPlanAsAdminMutation,
    UpdateSubscriptionPlanAsAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSubscriptionPlanAsAdminMutation,
    UpdateSubscriptionPlanAsAdminMutationVariables
  >(UpdateSubscriptionPlanAsAdminDocument, options);
}
export type UpdateSubscriptionPlanAsAdminMutationHookResult = ReturnType<
  typeof useUpdateSubscriptionPlanAsAdminMutation
>;
export type UpdateSubscriptionPlanAsAdminMutationResult =
  Apollo.MutationResult<UpdateSubscriptionPlanAsAdminMutation>;
export type UpdateSubscriptionPlanAsAdminMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateSubscriptionPlanAsAdminMutation,
    UpdateSubscriptionPlanAsAdminMutationVariables
  >;
export const UpdateExtraPlanAsAdminDocument = gql`
  mutation UpdateExtraPlanAsAdmin($extraPlanInput: ExtraPlanInput!) {
    updateExtraPlanAsAdmin(extraPlanInput: $extraPlanInput) {
      id
      tickets
      initialPrice
      finalPrice
      active
      createdAt
      updatedAt
    }
  }
`;
export type UpdateExtraPlanAsAdminMutationFn = Apollo.MutationFunction<
  UpdateExtraPlanAsAdminMutation,
  UpdateExtraPlanAsAdminMutationVariables
>;

/**
 * __useUpdateExtraPlanAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateExtraPlanAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExtraPlanAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExtraPlanAsAdminMutation, { data, loading, error }] = useUpdateExtraPlanAsAdminMutation({
 *   variables: {
 *      extraPlanInput: // value for 'extraPlanInput'
 *   },
 * });
 */
export function useUpdateExtraPlanAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateExtraPlanAsAdminMutation,
    UpdateExtraPlanAsAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateExtraPlanAsAdminMutation,
    UpdateExtraPlanAsAdminMutationVariables
  >(UpdateExtraPlanAsAdminDocument, options);
}
export type UpdateExtraPlanAsAdminMutationHookResult = ReturnType<
  typeof useUpdateExtraPlanAsAdminMutation
>;
export type UpdateExtraPlanAsAdminMutationResult =
  Apollo.MutationResult<UpdateExtraPlanAsAdminMutation>;
export type UpdateExtraPlanAsAdminMutationOptions = Apollo.BaseMutationOptions<
  UpdateExtraPlanAsAdminMutation,
  UpdateExtraPlanAsAdminMutationVariables
>;
export const GetLanguageOptionsDocument = gql`
  query GetLanguageOptions {
    getLanguageOptions {
      id
      language
      name
      active
      order
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetLanguageOptionsQuery__
 *
 * To run a query within a React component, call `useGetLanguageOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguageOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguageOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLanguageOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLanguageOptionsQuery,
    GetLanguageOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLanguageOptionsQuery,
    GetLanguageOptionsQueryVariables
  >(GetLanguageOptionsDocument, options);
}
export function useGetLanguageOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLanguageOptionsQuery,
    GetLanguageOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLanguageOptionsQuery,
    GetLanguageOptionsQueryVariables
  >(GetLanguageOptionsDocument, options);
}
export function useGetLanguageOptionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetLanguageOptionsQuery,
    GetLanguageOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetLanguageOptionsQuery,
    GetLanguageOptionsQueryVariables
  >(GetLanguageOptionsDocument, options);
}
export type GetLanguageOptionsQueryHookResult = ReturnType<
  typeof useGetLanguageOptionsQuery
>;
export type GetLanguageOptionsLazyQueryHookResult = ReturnType<
  typeof useGetLanguageOptionsLazyQuery
>;
export type GetLanguageOptionsSuspenseQueryHookResult = ReturnType<
  typeof useGetLanguageOptionsSuspenseQuery
>;
export type GetLanguageOptionsQueryResult = Apollo.QueryResult<
  GetLanguageOptionsQuery,
  GetLanguageOptionsQueryVariables
>;
export const UpdateLanguageOptionAsAdminDocument = gql`
  mutation UpdateLanguageOptionAsAdmin(
    $languageOptionInput: LanguageOptionInput!
  ) {
    updateLanguageOptionAsAdmin(languageOptionInput: $languageOptionInput) {
      id
      language
      name
      active
      order
      createdAt
      updatedAt
    }
  }
`;
export type UpdateLanguageOptionAsAdminMutationFn = Apollo.MutationFunction<
  UpdateLanguageOptionAsAdminMutation,
  UpdateLanguageOptionAsAdminMutationVariables
>;

/**
 * __useUpdateLanguageOptionAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateLanguageOptionAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLanguageOptionAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLanguageOptionAsAdminMutation, { data, loading, error }] = useUpdateLanguageOptionAsAdminMutation({
 *   variables: {
 *      languageOptionInput: // value for 'languageOptionInput'
 *   },
 * });
 */
export function useUpdateLanguageOptionAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLanguageOptionAsAdminMutation,
    UpdateLanguageOptionAsAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLanguageOptionAsAdminMutation,
    UpdateLanguageOptionAsAdminMutationVariables
  >(UpdateLanguageOptionAsAdminDocument, options);
}
export type UpdateLanguageOptionAsAdminMutationHookResult = ReturnType<
  typeof useUpdateLanguageOptionAsAdminMutation
>;
export type UpdateLanguageOptionAsAdminMutationResult =
  Apollo.MutationResult<UpdateLanguageOptionAsAdminMutation>;
export type UpdateLanguageOptionAsAdminMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateLanguageOptionAsAdminMutation,
    UpdateLanguageOptionAsAdminMutationVariables
  >;
export const GetManagementDocument = gql`
  query GetManagement {
    getManagement {
      id
      companyName
      address
      copyright
      email
      ceoName
      registrationNumber
      escroRegistration
      phone
      kakaoAddress
      sessionDuration
      shopAddress
      paymentWarning
      paymentInformation
    }
  }
`;

/**
 * __useGetManagementQuery__
 *
 * To run a query within a React component, call `useGetManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManagementQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManagementQuery,
    GetManagementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManagementQuery, GetManagementQueryVariables>(
    GetManagementDocument,
    options
  );
}
export function useGetManagementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementQuery,
    GetManagementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManagementQuery, GetManagementQueryVariables>(
    GetManagementDocument,
    options
  );
}
export function useGetManagementSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetManagementQuery,
    GetManagementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetManagementQuery,
    GetManagementQueryVariables
  >(GetManagementDocument, options);
}
export type GetManagementQueryHookResult = ReturnType<
  typeof useGetManagementQuery
>;
export type GetManagementLazyQueryHookResult = ReturnType<
  typeof useGetManagementLazyQuery
>;
export type GetManagementSuspenseQueryHookResult = ReturnType<
  typeof useGetManagementSuspenseQuery
>;
export type GetManagementQueryResult = Apollo.QueryResult<
  GetManagementQuery,
  GetManagementQueryVariables
>;
export const UpdateManagementAsAdminDocument = gql`
  mutation UpdateManagementAsAdmin($managementInput: ManagementInput!) {
    updateManagementAsAdmin(managementInput: $managementInput) {
      id
      companyName
      address
      copyright
      email
      ceoName
      registrationNumber
      escroRegistration
      phone
      kakaoAddress
      sessionDuration
      shopAddress
      paymentWarning
      paymentInformation
    }
  }
`;
export type UpdateManagementAsAdminMutationFn = Apollo.MutationFunction<
  UpdateManagementAsAdminMutation,
  UpdateManagementAsAdminMutationVariables
>;

/**
 * __useUpdateManagementAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateManagementAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManagementAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManagementAsAdminMutation, { data, loading, error }] = useUpdateManagementAsAdminMutation({
 *   variables: {
 *      managementInput: // value for 'managementInput'
 *   },
 * });
 */
export function useUpdateManagementAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateManagementAsAdminMutation,
    UpdateManagementAsAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateManagementAsAdminMutation,
    UpdateManagementAsAdminMutationVariables
  >(UpdateManagementAsAdminDocument, options);
}
export type UpdateManagementAsAdminMutationHookResult = ReturnType<
  typeof useUpdateManagementAsAdminMutation
>;
export type UpdateManagementAsAdminMutationResult =
  Apollo.MutationResult<UpdateManagementAsAdminMutation>;
export type UpdateManagementAsAdminMutationOptions = Apollo.BaseMutationOptions<
  UpdateManagementAsAdminMutation,
  UpdateManagementAsAdminMutationVariables
>;
export const GetPaymentUnitPricesAsAdminDocument = gql`
  query GetPaymentUnitPricesAsAdmin {
    getPaymentUnitPricesAsAdmin {
      id
      language
      unitPrice
      currency
      type
    }
  }
`;

/**
 * __useGetPaymentUnitPricesAsAdminQuery__
 *
 * To run a query within a React component, call `useGetPaymentUnitPricesAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentUnitPricesAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentUnitPricesAsAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentUnitPricesAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPaymentUnitPricesAsAdminQuery,
    GetPaymentUnitPricesAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPaymentUnitPricesAsAdminQuery,
    GetPaymentUnitPricesAsAdminQueryVariables
  >(GetPaymentUnitPricesAsAdminDocument, options);
}
export function useGetPaymentUnitPricesAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentUnitPricesAsAdminQuery,
    GetPaymentUnitPricesAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPaymentUnitPricesAsAdminQuery,
    GetPaymentUnitPricesAsAdminQueryVariables
  >(GetPaymentUnitPricesAsAdminDocument, options);
}
export function useGetPaymentUnitPricesAsAdminSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPaymentUnitPricesAsAdminQuery,
    GetPaymentUnitPricesAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPaymentUnitPricesAsAdminQuery,
    GetPaymentUnitPricesAsAdminQueryVariables
  >(GetPaymentUnitPricesAsAdminDocument, options);
}
export type GetPaymentUnitPricesAsAdminQueryHookResult = ReturnType<
  typeof useGetPaymentUnitPricesAsAdminQuery
>;
export type GetPaymentUnitPricesAsAdminLazyQueryHookResult = ReturnType<
  typeof useGetPaymentUnitPricesAsAdminLazyQuery
>;
export type GetPaymentUnitPricesAsAdminSuspenseQueryHookResult = ReturnType<
  typeof useGetPaymentUnitPricesAsAdminSuspenseQuery
>;
export type GetPaymentUnitPricesAsAdminQueryResult = Apollo.QueryResult<
  GetPaymentUnitPricesAsAdminQuery,
  GetPaymentUnitPricesAsAdminQueryVariables
>;
export const UpdatePaymentUnitPriceAsAdminDocument = gql`
  mutation UpdatePaymentUnitPriceAsAdmin(
    $paymentUnitPriceInput: PaymentUnitPriceInput!
  ) {
    updatePaymentUnitPriceAsAdmin(
      paymentUnitPriceInput: $paymentUnitPriceInput
    ) {
      id
      language
      unitPrice
      currency
      type
    }
  }
`;
export type UpdatePaymentUnitPriceAsAdminMutationFn = Apollo.MutationFunction<
  UpdatePaymentUnitPriceAsAdminMutation,
  UpdatePaymentUnitPriceAsAdminMutationVariables
>;

/**
 * __useUpdatePaymentUnitPriceAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentUnitPriceAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentUnitPriceAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentUnitPriceAsAdminMutation, { data, loading, error }] = useUpdatePaymentUnitPriceAsAdminMutation({
 *   variables: {
 *      paymentUnitPriceInput: // value for 'paymentUnitPriceInput'
 *   },
 * });
 */
export function useUpdatePaymentUnitPriceAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePaymentUnitPriceAsAdminMutation,
    UpdatePaymentUnitPriceAsAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePaymentUnitPriceAsAdminMutation,
    UpdatePaymentUnitPriceAsAdminMutationVariables
  >(UpdatePaymentUnitPriceAsAdminDocument, options);
}
export type UpdatePaymentUnitPriceAsAdminMutationHookResult = ReturnType<
  typeof useUpdatePaymentUnitPriceAsAdminMutation
>;
export type UpdatePaymentUnitPriceAsAdminMutationResult =
  Apollo.MutationResult<UpdatePaymentUnitPriceAsAdminMutation>;
export type UpdatePaymentUnitPriceAsAdminMutationOptions =
  Apollo.BaseMutationOptions<
    UpdatePaymentUnitPriceAsAdminMutation,
    UpdatePaymentUnitPriceAsAdminMutationVariables
  >;
export const GetUnpaidSessionsAsAdminDocument = gql`
  query GetUnpaidSessionsAsAdmin {
    getUnpaidSessionsAsAdmin {
      id
      requester {
        id
        name
      }
      instructor {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
        }
      }
      interpreter {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
        }
      }
      userPass {
        id
        pass {
          id
          name
        }
      }
      userSubscription {
        id
      }
      startAt
      endAt
      report
      needInterpreter
      status
      users {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
        }
        joinAt
        leaveAt
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetUnpaidSessionsAsAdminQuery__
 *
 * To run a query within a React component, call `useGetUnpaidSessionsAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnpaidSessionsAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnpaidSessionsAsAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnpaidSessionsAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUnpaidSessionsAsAdminQuery,
    GetUnpaidSessionsAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUnpaidSessionsAsAdminQuery,
    GetUnpaidSessionsAsAdminQueryVariables
  >(GetUnpaidSessionsAsAdminDocument, options);
}
export function useGetUnpaidSessionsAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnpaidSessionsAsAdminQuery,
    GetUnpaidSessionsAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUnpaidSessionsAsAdminQuery,
    GetUnpaidSessionsAsAdminQueryVariables
  >(GetUnpaidSessionsAsAdminDocument, options);
}
export function useGetUnpaidSessionsAsAdminSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUnpaidSessionsAsAdminQuery,
    GetUnpaidSessionsAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUnpaidSessionsAsAdminQuery,
    GetUnpaidSessionsAsAdminQueryVariables
  >(GetUnpaidSessionsAsAdminDocument, options);
}
export type GetUnpaidSessionsAsAdminQueryHookResult = ReturnType<
  typeof useGetUnpaidSessionsAsAdminQuery
>;
export type GetUnpaidSessionsAsAdminLazyQueryHookResult = ReturnType<
  typeof useGetUnpaidSessionsAsAdminLazyQuery
>;
export type GetUnpaidSessionsAsAdminSuspenseQueryHookResult = ReturnType<
  typeof useGetUnpaidSessionsAsAdminSuspenseQuery
>;
export type GetUnpaidSessionsAsAdminQueryResult = Apollo.QueryResult<
  GetUnpaidSessionsAsAdminQuery,
  GetUnpaidSessionsAsAdminQueryVariables
>;
export const GetInterpreterUnpaidSessionAsAdminDocument = gql`
  query GetInterpreterUnpaidSessionAsAdmin {
    getInterpreterUnpaidSessionAsAdmin {
      id
      requester {
        id
        name
      }
      instructor {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
        }
      }
      interpreter {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
        }
      }
      userPass {
        id
        pass {
          id
          name
        }
      }
      userSubscription {
        id
      }
      startAt
      endAt
      report
      needInterpreter
      status
      users {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
        }
        joinAt
        leaveAt
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetInterpreterUnpaidSessionAsAdminQuery__
 *
 * To run a query within a React component, call `useGetInterpreterUnpaidSessionAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInterpreterUnpaidSessionAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInterpreterUnpaidSessionAsAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInterpreterUnpaidSessionAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInterpreterUnpaidSessionAsAdminQuery,
    GetInterpreterUnpaidSessionAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInterpreterUnpaidSessionAsAdminQuery,
    GetInterpreterUnpaidSessionAsAdminQueryVariables
  >(GetInterpreterUnpaidSessionAsAdminDocument, options);
}
export function useGetInterpreterUnpaidSessionAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInterpreterUnpaidSessionAsAdminQuery,
    GetInterpreterUnpaidSessionAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInterpreterUnpaidSessionAsAdminQuery,
    GetInterpreterUnpaidSessionAsAdminQueryVariables
  >(GetInterpreterUnpaidSessionAsAdminDocument, options);
}
export function useGetInterpreterUnpaidSessionAsAdminSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetInterpreterUnpaidSessionAsAdminQuery,
    GetInterpreterUnpaidSessionAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetInterpreterUnpaidSessionAsAdminQuery,
    GetInterpreterUnpaidSessionAsAdminQueryVariables
  >(GetInterpreterUnpaidSessionAsAdminDocument, options);
}
export type GetInterpreterUnpaidSessionAsAdminQueryHookResult = ReturnType<
  typeof useGetInterpreterUnpaidSessionAsAdminQuery
>;
export type GetInterpreterUnpaidSessionAsAdminLazyQueryHookResult = ReturnType<
  typeof useGetInterpreterUnpaidSessionAsAdminLazyQuery
>;
export type GetInterpreterUnpaidSessionAsAdminSuspenseQueryHookResult =
  ReturnType<typeof useGetInterpreterUnpaidSessionAsAdminSuspenseQuery>;
export type GetInterpreterUnpaidSessionAsAdminQueryResult = Apollo.QueryResult<
  GetInterpreterUnpaidSessionAsAdminQuery,
  GetInterpreterUnpaidSessionAsAdminQueryVariables
>;
export const AddPaymentsAsAdminDocument = gql`
  mutation AddPaymentsAsAdmin($paymentInput: PaymentInput!) {
    addPaymentsAsAdmin(paymentInput: $paymentInput)
  }
`;
export type AddPaymentsAsAdminMutationFn = Apollo.MutationFunction<
  AddPaymentsAsAdminMutation,
  AddPaymentsAsAdminMutationVariables
>;

/**
 * __useAddPaymentsAsAdminMutation__
 *
 * To run a mutation, you first call `useAddPaymentsAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentsAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentsAsAdminMutation, { data, loading, error }] = useAddPaymentsAsAdminMutation({
 *   variables: {
 *      paymentInput: // value for 'paymentInput'
 *   },
 * });
 */
export function useAddPaymentsAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPaymentsAsAdminMutation,
    AddPaymentsAsAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPaymentsAsAdminMutation,
    AddPaymentsAsAdminMutationVariables
  >(AddPaymentsAsAdminDocument, options);
}
export type AddPaymentsAsAdminMutationHookResult = ReturnType<
  typeof useAddPaymentsAsAdminMutation
>;
export type AddPaymentsAsAdminMutationResult =
  Apollo.MutationResult<AddPaymentsAsAdminMutation>;
export type AddPaymentsAsAdminMutationOptions = Apollo.BaseMutationOptions<
  AddPaymentsAsAdminMutation,
  AddPaymentsAsAdminMutationVariables
>;
export const GetUserBankAccountAsAdminDocument = gql`
  query GetUserBankAccountAsAdmin($userId: ID!) {
    getUserBankAccountAsAdmin(userId: $userId) {
      id
      user {
        id
      }
      bankName
      accountNumber
    }
  }
`;

/**
 * __useGetUserBankAccountAsAdminQuery__
 *
 * To run a query within a React component, call `useGetUserBankAccountAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBankAccountAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBankAccountAsAdminQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserBankAccountAsAdminQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserBankAccountAsAdminQuery,
    GetUserBankAccountAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserBankAccountAsAdminQuery,
    GetUserBankAccountAsAdminQueryVariables
  >(GetUserBankAccountAsAdminDocument, options);
}
export function useGetUserBankAccountAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserBankAccountAsAdminQuery,
    GetUserBankAccountAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserBankAccountAsAdminQuery,
    GetUserBankAccountAsAdminQueryVariables
  >(GetUserBankAccountAsAdminDocument, options);
}
export function useGetUserBankAccountAsAdminSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserBankAccountAsAdminQuery,
    GetUserBankAccountAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserBankAccountAsAdminQuery,
    GetUserBankAccountAsAdminQueryVariables
  >(GetUserBankAccountAsAdminDocument, options);
}
export type GetUserBankAccountAsAdminQueryHookResult = ReturnType<
  typeof useGetUserBankAccountAsAdminQuery
>;
export type GetUserBankAccountAsAdminLazyQueryHookResult = ReturnType<
  typeof useGetUserBankAccountAsAdminLazyQuery
>;
export type GetUserBankAccountAsAdminSuspenseQueryHookResult = ReturnType<
  typeof useGetUserBankAccountAsAdminSuspenseQuery
>;
export type GetUserBankAccountAsAdminQueryResult = Apollo.QueryResult<
  GetUserBankAccountAsAdminQuery,
  GetUserBankAccountAsAdminQueryVariables
>;
export const GetAllergyTypesAsAdminDocument = gql`
  query GetAllergyTypesAsAdmin {
    getAllergyTypesAsAdmin {
      id
      language
      name
      order
      active
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetAllergyTypesAsAdminQuery__
 *
 * To run a query within a React component, call `useGetAllergyTypesAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllergyTypesAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllergyTypesAsAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllergyTypesAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllergyTypesAsAdminQuery,
    GetAllergyTypesAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllergyTypesAsAdminQuery,
    GetAllergyTypesAsAdminQueryVariables
  >(GetAllergyTypesAsAdminDocument, options);
}
export function useGetAllergyTypesAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllergyTypesAsAdminQuery,
    GetAllergyTypesAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllergyTypesAsAdminQuery,
    GetAllergyTypesAsAdminQueryVariables
  >(GetAllergyTypesAsAdminDocument, options);
}
export function useGetAllergyTypesAsAdminSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAllergyTypesAsAdminQuery,
    GetAllergyTypesAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAllergyTypesAsAdminQuery,
    GetAllergyTypesAsAdminQueryVariables
  >(GetAllergyTypesAsAdminDocument, options);
}
export type GetAllergyTypesAsAdminQueryHookResult = ReturnType<
  typeof useGetAllergyTypesAsAdminQuery
>;
export type GetAllergyTypesAsAdminLazyQueryHookResult = ReturnType<
  typeof useGetAllergyTypesAsAdminLazyQuery
>;
export type GetAllergyTypesAsAdminSuspenseQueryHookResult = ReturnType<
  typeof useGetAllergyTypesAsAdminSuspenseQuery
>;
export type GetAllergyTypesAsAdminQueryResult = Apollo.QueryResult<
  GetAllergyTypesAsAdminQuery,
  GetAllergyTypesAsAdminQueryVariables
>;
export const GetAllergySymptomsAsAdminDocument = gql`
  query GetAllergySymptomsAsAdmin {
    getAllergySymptomsAsAdmin {
      id
      language
      name
      order
      active
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetAllergySymptomsAsAdminQuery__
 *
 * To run a query within a React component, call `useGetAllergySymptomsAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllergySymptomsAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllergySymptomsAsAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllergySymptomsAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllergySymptomsAsAdminQuery,
    GetAllergySymptomsAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllergySymptomsAsAdminQuery,
    GetAllergySymptomsAsAdminQueryVariables
  >(GetAllergySymptomsAsAdminDocument, options);
}
export function useGetAllergySymptomsAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllergySymptomsAsAdminQuery,
    GetAllergySymptomsAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllergySymptomsAsAdminQuery,
    GetAllergySymptomsAsAdminQueryVariables
  >(GetAllergySymptomsAsAdminDocument, options);
}
export function useGetAllergySymptomsAsAdminSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAllergySymptomsAsAdminQuery,
    GetAllergySymptomsAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAllergySymptomsAsAdminQuery,
    GetAllergySymptomsAsAdminQueryVariables
  >(GetAllergySymptomsAsAdminDocument, options);
}
export type GetAllergySymptomsAsAdminQueryHookResult = ReturnType<
  typeof useGetAllergySymptomsAsAdminQuery
>;
export type GetAllergySymptomsAsAdminLazyQueryHookResult = ReturnType<
  typeof useGetAllergySymptomsAsAdminLazyQuery
>;
export type GetAllergySymptomsAsAdminSuspenseQueryHookResult = ReturnType<
  typeof useGetAllergySymptomsAsAdminSuspenseQuery
>;
export type GetAllergySymptomsAsAdminQueryResult = Apollo.QueryResult<
  GetAllergySymptomsAsAdminQuery,
  GetAllergySymptomsAsAdminQueryVariables
>;
export const GetChronicDiseaseTypesAsAdminDocument = gql`
  query GetChronicDiseaseTypesAsAdmin {
    getChronicDiseaseTypesAsAdmin {
      id
      language
      name
      order
      active
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetChronicDiseaseTypesAsAdminQuery__
 *
 * To run a query within a React component, call `useGetChronicDiseaseTypesAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChronicDiseaseTypesAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChronicDiseaseTypesAsAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChronicDiseaseTypesAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetChronicDiseaseTypesAsAdminQuery,
    GetChronicDiseaseTypesAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetChronicDiseaseTypesAsAdminQuery,
    GetChronicDiseaseTypesAsAdminQueryVariables
  >(GetChronicDiseaseTypesAsAdminDocument, options);
}
export function useGetChronicDiseaseTypesAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChronicDiseaseTypesAsAdminQuery,
    GetChronicDiseaseTypesAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetChronicDiseaseTypesAsAdminQuery,
    GetChronicDiseaseTypesAsAdminQueryVariables
  >(GetChronicDiseaseTypesAsAdminDocument, options);
}
export function useGetChronicDiseaseTypesAsAdminSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetChronicDiseaseTypesAsAdminQuery,
    GetChronicDiseaseTypesAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetChronicDiseaseTypesAsAdminQuery,
    GetChronicDiseaseTypesAsAdminQueryVariables
  >(GetChronicDiseaseTypesAsAdminDocument, options);
}
export type GetChronicDiseaseTypesAsAdminQueryHookResult = ReturnType<
  typeof useGetChronicDiseaseTypesAsAdminQuery
>;
export type GetChronicDiseaseTypesAsAdminLazyQueryHookResult = ReturnType<
  typeof useGetChronicDiseaseTypesAsAdminLazyQuery
>;
export type GetChronicDiseaseTypesAsAdminSuspenseQueryHookResult = ReturnType<
  typeof useGetChronicDiseaseTypesAsAdminSuspenseQuery
>;
export type GetChronicDiseaseTypesAsAdminQueryResult = Apollo.QueryResult<
  GetChronicDiseaseTypesAsAdminQuery,
  GetChronicDiseaseTypesAsAdminQueryVariables
>;
export const GetVaccineTypesAsAdminDocument = gql`
  query GetVaccineTypesAsAdmin {
    getVaccineTypesAsAdmin {
      id
      language
      name
      order
      active
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetVaccineTypesAsAdminQuery__
 *
 * To run a query within a React component, call `useGetVaccineTypesAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVaccineTypesAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVaccineTypesAsAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVaccineTypesAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVaccineTypesAsAdminQuery,
    GetVaccineTypesAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVaccineTypesAsAdminQuery,
    GetVaccineTypesAsAdminQueryVariables
  >(GetVaccineTypesAsAdminDocument, options);
}
export function useGetVaccineTypesAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVaccineTypesAsAdminQuery,
    GetVaccineTypesAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVaccineTypesAsAdminQuery,
    GetVaccineTypesAsAdminQueryVariables
  >(GetVaccineTypesAsAdminDocument, options);
}
export function useGetVaccineTypesAsAdminSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetVaccineTypesAsAdminQuery,
    GetVaccineTypesAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetVaccineTypesAsAdminQuery,
    GetVaccineTypesAsAdminQueryVariables
  >(GetVaccineTypesAsAdminDocument, options);
}
export type GetVaccineTypesAsAdminQueryHookResult = ReturnType<
  typeof useGetVaccineTypesAsAdminQuery
>;
export type GetVaccineTypesAsAdminLazyQueryHookResult = ReturnType<
  typeof useGetVaccineTypesAsAdminLazyQuery
>;
export type GetVaccineTypesAsAdminSuspenseQueryHookResult = ReturnType<
  typeof useGetVaccineTypesAsAdminSuspenseQuery
>;
export type GetVaccineTypesAsAdminQueryResult = Apollo.QueryResult<
  GetVaccineTypesAsAdminQuery,
  GetVaccineTypesAsAdminQueryVariables
>;
export const UpdateChronicDiseaseTypeAsAdminDocument = gql`
  mutation UpdateChronicDiseaseTypeAsAdmin($typeInput: PHRTypeInput) {
    updateChronicDiseaseTypeAsAdmin(typeInput: $typeInput) {
      id
      language
      name
      order
      active
      createdAt
      updatedAt
    }
  }
`;
export type UpdateChronicDiseaseTypeAsAdminMutationFn = Apollo.MutationFunction<
  UpdateChronicDiseaseTypeAsAdminMutation,
  UpdateChronicDiseaseTypeAsAdminMutationVariables
>;

/**
 * __useUpdateChronicDiseaseTypeAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateChronicDiseaseTypeAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChronicDiseaseTypeAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChronicDiseaseTypeAsAdminMutation, { data, loading, error }] = useUpdateChronicDiseaseTypeAsAdminMutation({
 *   variables: {
 *      typeInput: // value for 'typeInput'
 *   },
 * });
 */
export function useUpdateChronicDiseaseTypeAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChronicDiseaseTypeAsAdminMutation,
    UpdateChronicDiseaseTypeAsAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateChronicDiseaseTypeAsAdminMutation,
    UpdateChronicDiseaseTypeAsAdminMutationVariables
  >(UpdateChronicDiseaseTypeAsAdminDocument, options);
}
export type UpdateChronicDiseaseTypeAsAdminMutationHookResult = ReturnType<
  typeof useUpdateChronicDiseaseTypeAsAdminMutation
>;
export type UpdateChronicDiseaseTypeAsAdminMutationResult =
  Apollo.MutationResult<UpdateChronicDiseaseTypeAsAdminMutation>;
export type UpdateChronicDiseaseTypeAsAdminMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateChronicDiseaseTypeAsAdminMutation,
    UpdateChronicDiseaseTypeAsAdminMutationVariables
  >;
export const UpdateAllergyTypeAsAdminDocument = gql`
  mutation UpdateAllergyTypeAsAdmin($typeInput: PHRTypeInput) {
    updateAllergyTypeAsAdmin(typeInput: $typeInput) {
      id
      language
      name
      order
      active
      createdAt
      updatedAt
    }
  }
`;
export type UpdateAllergyTypeAsAdminMutationFn = Apollo.MutationFunction<
  UpdateAllergyTypeAsAdminMutation,
  UpdateAllergyTypeAsAdminMutationVariables
>;

/**
 * __useUpdateAllergyTypeAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateAllergyTypeAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAllergyTypeAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllergyTypeAsAdminMutation, { data, loading, error }] = useUpdateAllergyTypeAsAdminMutation({
 *   variables: {
 *      typeInput: // value for 'typeInput'
 *   },
 * });
 */
export function useUpdateAllergyTypeAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAllergyTypeAsAdminMutation,
    UpdateAllergyTypeAsAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAllergyTypeAsAdminMutation,
    UpdateAllergyTypeAsAdminMutationVariables
  >(UpdateAllergyTypeAsAdminDocument, options);
}
export type UpdateAllergyTypeAsAdminMutationHookResult = ReturnType<
  typeof useUpdateAllergyTypeAsAdminMutation
>;
export type UpdateAllergyTypeAsAdminMutationResult =
  Apollo.MutationResult<UpdateAllergyTypeAsAdminMutation>;
export type UpdateAllergyTypeAsAdminMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAllergyTypeAsAdminMutation,
    UpdateAllergyTypeAsAdminMutationVariables
  >;
export const UpdateAllergySymptomAsAdminDocument = gql`
  mutation UpdateAllergySymptomAsAdmin($typeInput: PHRTypeInput) {
    updateAllergySymptomAsAdmin(typeInput: $typeInput) {
      id
      language
      name
      order
      active
      createdAt
      updatedAt
    }
  }
`;
export type UpdateAllergySymptomAsAdminMutationFn = Apollo.MutationFunction<
  UpdateAllergySymptomAsAdminMutation,
  UpdateAllergySymptomAsAdminMutationVariables
>;

/**
 * __useUpdateAllergySymptomAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateAllergySymptomAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAllergySymptomAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllergySymptomAsAdminMutation, { data, loading, error }] = useUpdateAllergySymptomAsAdminMutation({
 *   variables: {
 *      typeInput: // value for 'typeInput'
 *   },
 * });
 */
export function useUpdateAllergySymptomAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAllergySymptomAsAdminMutation,
    UpdateAllergySymptomAsAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAllergySymptomAsAdminMutation,
    UpdateAllergySymptomAsAdminMutationVariables
  >(UpdateAllergySymptomAsAdminDocument, options);
}
export type UpdateAllergySymptomAsAdminMutationHookResult = ReturnType<
  typeof useUpdateAllergySymptomAsAdminMutation
>;
export type UpdateAllergySymptomAsAdminMutationResult =
  Apollo.MutationResult<UpdateAllergySymptomAsAdminMutation>;
export type UpdateAllergySymptomAsAdminMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAllergySymptomAsAdminMutation,
    UpdateAllergySymptomAsAdminMutationVariables
  >;
export const UpdateVaccineTypeAsAdminDocument = gql`
  mutation UpdateVaccineTypeAsAdmin($typeInput: PHRTypeInput) {
    updateVaccineTypeAsAdmin(typeInput: $typeInput) {
      id
      language
      name
      order
      active
      createdAt
      updatedAt
    }
  }
`;
export type UpdateVaccineTypeAsAdminMutationFn = Apollo.MutationFunction<
  UpdateVaccineTypeAsAdminMutation,
  UpdateVaccineTypeAsAdminMutationVariables
>;

/**
 * __useUpdateVaccineTypeAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateVaccineTypeAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVaccineTypeAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVaccineTypeAsAdminMutation, { data, loading, error }] = useUpdateVaccineTypeAsAdminMutation({
 *   variables: {
 *      typeInput: // value for 'typeInput'
 *   },
 * });
 */
export function useUpdateVaccineTypeAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVaccineTypeAsAdminMutation,
    UpdateVaccineTypeAsAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVaccineTypeAsAdminMutation,
    UpdateVaccineTypeAsAdminMutationVariables
  >(UpdateVaccineTypeAsAdminDocument, options);
}
export type UpdateVaccineTypeAsAdminMutationHookResult = ReturnType<
  typeof useUpdateVaccineTypeAsAdminMutation
>;
export type UpdateVaccineTypeAsAdminMutationResult =
  Apollo.MutationResult<UpdateVaccineTypeAsAdminMutation>;
export type UpdateVaccineTypeAsAdminMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateVaccineTypeAsAdminMutation,
    UpdateVaccineTypeAsAdminMutationVariables
  >;
export const GetUserDocument = gql`
  query GetUser($userId: ID!) {
    getUser(userId: $userId) {
      id
      type
      email
      name
      gender
      phoneCode
      phone
      dateOfBirth
      provider
      avatar {
        id
        uri
      }
      resignReason
      nationality {
        id
        name
      }
      profile {
        id
        user {
          id
        }
        weight
        height
        bloodType
        occupation
        companyName
        emergencyCallerName
        emergencyCallerNationality {
          id
          name
        }
        emergencyCallerPhoneCode
        emergencyCallerPhone
        emergencyCallerBloodType
        emergencyCallerRelation
        emergencyCallerAddress
        createdAt
        updatedAt
      }
      instructor {
        id
        user {
          id
        }
        introduction
        job
        qualificationNumber
        educations {
          id
          school
          major
          createdAt
          updatedAt
        }
        experiences {
          id
          place
          job
          duration
          createdAt
          updatedAt
        }
        online
        createdAt
        updatedAt
      }
      interpreter {
        id
        user {
          id
        }
        introduction
        visaNumber
        passportNumber
        educations {
          id
          school
          major
          createdAt
          updatedAt
        }
        experiences {
          id
          place
          job
          duration
          createdAt
          updatedAt
        }
        online
        createdAt
        updatedAt
      }
      languageOptions {
        id
        language
        name
        active
      }
      isSubscriber
      usuableTickets
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export function useGetUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserQuery,
    GetUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserSuspenseQueryHookResult = ReturnType<
  typeof useGetUserSuspenseQuery
>;
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>;
export const GetUsersAsAdminDocument = gql`
  query GetUsersAsAdmin($first: Int, $after: String) {
    getUsersAsAdmin(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        type
        email
        name
        gender
        phoneCode
        phone
        dateOfBirth
        provider
        avatar {
          id
          uri
        }
        resignReason
        nationality {
          id
          name
        }
        profile {
          id
        }
        instructor {
          id
        }
        interpreter {
          id
        }
        languageOptions {
          id
          name
        }
        isSubscriber
        usuableTickets
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useGetUsersAsAdminQuery__
 *
 * To run a query within a React component, call `useGetUsersAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersAsAdminQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetUsersAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUsersAsAdminQuery,
    GetUsersAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersAsAdminQuery, GetUsersAsAdminQueryVariables>(
    GetUsersAsAdminDocument,
    options
  );
}
export function useGetUsersAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersAsAdminQuery,
    GetUsersAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsersAsAdminQuery,
    GetUsersAsAdminQueryVariables
  >(GetUsersAsAdminDocument, options);
}
export function useGetUsersAsAdminSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUsersAsAdminQuery,
    GetUsersAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUsersAsAdminQuery,
    GetUsersAsAdminQueryVariables
  >(GetUsersAsAdminDocument, options);
}
export type GetUsersAsAdminQueryHookResult = ReturnType<
  typeof useGetUsersAsAdminQuery
>;
export type GetUsersAsAdminLazyQueryHookResult = ReturnType<
  typeof useGetUsersAsAdminLazyQuery
>;
export type GetUsersAsAdminSuspenseQueryHookResult = ReturnType<
  typeof useGetUsersAsAdminSuspenseQuery
>;
export type GetUsersAsAdminQueryResult = Apollo.QueryResult<
  GetUsersAsAdminQuery,
  GetUsersAsAdminQueryVariables
>;
export const GetAllInstructorsAsAdminDocument = gql`
  query GetAllInstructorsAsAdmin($first: Int, $after: String) {
    getAllInstructorsAsAdmin(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        user {
          id
          type
          email
          name
          gender
          phoneCode
          phone
          dateOfBirth
          provider
          avatar {
            id
            uri
          }
          resignReason
          nationality {
            id
            name
          }
          profile {
            id
          }
          instructor {
            id
          }
          interpreter {
            id
          }
          languageOptions {
            id
            name
          }
          isSubscriber
          usuableTickets
          createdAt
          updatedAt
          deletedAt
        }
        introduction
        job
        qualificationNumber
        educations {
          id
          school
          major
          createdAt
          updatedAt
        }
        experiences {
          id
          place
          job
          duration
          createdAt
          updatedAt
        }
        online
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useGetAllInstructorsAsAdminQuery__
 *
 * To run a query within a React component, call `useGetAllInstructorsAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInstructorsAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInstructorsAsAdminQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetAllInstructorsAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllInstructorsAsAdminQuery,
    GetAllInstructorsAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllInstructorsAsAdminQuery,
    GetAllInstructorsAsAdminQueryVariables
  >(GetAllInstructorsAsAdminDocument, options);
}
export function useGetAllInstructorsAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllInstructorsAsAdminQuery,
    GetAllInstructorsAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllInstructorsAsAdminQuery,
    GetAllInstructorsAsAdminQueryVariables
  >(GetAllInstructorsAsAdminDocument, options);
}
export function useGetAllInstructorsAsAdminSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAllInstructorsAsAdminQuery,
    GetAllInstructorsAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAllInstructorsAsAdminQuery,
    GetAllInstructorsAsAdminQueryVariables
  >(GetAllInstructorsAsAdminDocument, options);
}
export type GetAllInstructorsAsAdminQueryHookResult = ReturnType<
  typeof useGetAllInstructorsAsAdminQuery
>;
export type GetAllInstructorsAsAdminLazyQueryHookResult = ReturnType<
  typeof useGetAllInstructorsAsAdminLazyQuery
>;
export type GetAllInstructorsAsAdminSuspenseQueryHookResult = ReturnType<
  typeof useGetAllInstructorsAsAdminSuspenseQuery
>;
export type GetAllInstructorsAsAdminQueryResult = Apollo.QueryResult<
  GetAllInstructorsAsAdminQuery,
  GetAllInstructorsAsAdminQueryVariables
>;
export const GetAllInterpretersAsAdminDocument = gql`
  query GetAllInterpretersAsAdmin($first: Int, $after: String) {
    getAllInterpretersAsAdmin(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        user {
          id
          type
          email
          name
          gender
          phoneCode
          phone
          dateOfBirth
          provider
          avatar {
            id
            uri
          }
          resignReason
          nationality {
            id
            name
          }
          profile {
            id
          }
          instructor {
            id
          }
          interpreter {
            id
          }
          languageOptions {
            id
            name
          }
          isSubscriber
          usuableTickets
          createdAt
          updatedAt
          deletedAt
        }
        introduction
        visaNumber
        passportNumber
        educations {
          id
          school
          major
          createdAt
          updatedAt
        }
        experiences {
          id
          place
          job
          duration
          createdAt
          updatedAt
        }
        online
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useGetAllInterpretersAsAdminQuery__
 *
 * To run a query within a React component, call `useGetAllInterpretersAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInterpretersAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInterpretersAsAdminQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetAllInterpretersAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllInterpretersAsAdminQuery,
    GetAllInterpretersAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllInterpretersAsAdminQuery,
    GetAllInterpretersAsAdminQueryVariables
  >(GetAllInterpretersAsAdminDocument, options);
}
export function useGetAllInterpretersAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllInterpretersAsAdminQuery,
    GetAllInterpretersAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllInterpretersAsAdminQuery,
    GetAllInterpretersAsAdminQueryVariables
  >(GetAllInterpretersAsAdminDocument, options);
}
export function useGetAllInterpretersAsAdminSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAllInterpretersAsAdminQuery,
    GetAllInterpretersAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAllInterpretersAsAdminQuery,
    GetAllInterpretersAsAdminQueryVariables
  >(GetAllInterpretersAsAdminDocument, options);
}
export type GetAllInterpretersAsAdminQueryHookResult = ReturnType<
  typeof useGetAllInterpretersAsAdminQuery
>;
export type GetAllInterpretersAsAdminLazyQueryHookResult = ReturnType<
  typeof useGetAllInterpretersAsAdminLazyQuery
>;
export type GetAllInterpretersAsAdminSuspenseQueryHookResult = ReturnType<
  typeof useGetAllInterpretersAsAdminSuspenseQuery
>;
export type GetAllInterpretersAsAdminQueryResult = Apollo.QueryResult<
  GetAllInterpretersAsAdminQuery,
  GetAllInterpretersAsAdminQueryVariables
>;
export const GetUserAllergiesDocument = gql`
  query GetUserAllergies($userId: ID!) {
    getUserAllergies(userId: $userId) {
      id
      user {
        id
      }
      type {
        id
        language
        name
      }
      summary
      firstAid
      symptoms {
        id
        language
        name
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetUserAllergiesQuery__
 *
 * To run a query within a React component, call `useGetUserAllergiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAllergiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAllergiesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserAllergiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserAllergiesQuery,
    GetUserAllergiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserAllergiesQuery, GetUserAllergiesQueryVariables>(
    GetUserAllergiesDocument,
    options
  );
}
export function useGetUserAllergiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAllergiesQuery,
    GetUserAllergiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserAllergiesQuery,
    GetUserAllergiesQueryVariables
  >(GetUserAllergiesDocument, options);
}
export function useGetUserAllergiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserAllergiesQuery,
    GetUserAllergiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserAllergiesQuery,
    GetUserAllergiesQueryVariables
  >(GetUserAllergiesDocument, options);
}
export type GetUserAllergiesQueryHookResult = ReturnType<
  typeof useGetUserAllergiesQuery
>;
export type GetUserAllergiesLazyQueryHookResult = ReturnType<
  typeof useGetUserAllergiesLazyQuery
>;
export type GetUserAllergiesSuspenseQueryHookResult = ReturnType<
  typeof useGetUserAllergiesSuspenseQuery
>;
export type GetUserAllergiesQueryResult = Apollo.QueryResult<
  GetUserAllergiesQuery,
  GetUserAllergiesQueryVariables
>;
export const GetUserChronicDiseasesDocument = gql`
  query GetUserChronicDiseases($userId: ID!) {
    getUserChronicDiseases(userId: $userId) {
      id
      user {
        id
      }
      type {
        id
        language
        name
      }
      etc
      medicine
      firstAid
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetUserChronicDiseasesQuery__
 *
 * To run a query within a React component, call `useGetUserChronicDiseasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserChronicDiseasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserChronicDiseasesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserChronicDiseasesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserChronicDiseasesQuery,
    GetUserChronicDiseasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserChronicDiseasesQuery,
    GetUserChronicDiseasesQueryVariables
  >(GetUserChronicDiseasesDocument, options);
}
export function useGetUserChronicDiseasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserChronicDiseasesQuery,
    GetUserChronicDiseasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserChronicDiseasesQuery,
    GetUserChronicDiseasesQueryVariables
  >(GetUserChronicDiseasesDocument, options);
}
export function useGetUserChronicDiseasesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserChronicDiseasesQuery,
    GetUserChronicDiseasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserChronicDiseasesQuery,
    GetUserChronicDiseasesQueryVariables
  >(GetUserChronicDiseasesDocument, options);
}
export type GetUserChronicDiseasesQueryHookResult = ReturnType<
  typeof useGetUserChronicDiseasesQuery
>;
export type GetUserChronicDiseasesLazyQueryHookResult = ReturnType<
  typeof useGetUserChronicDiseasesLazyQuery
>;
export type GetUserChronicDiseasesSuspenseQueryHookResult = ReturnType<
  typeof useGetUserChronicDiseasesSuspenseQuery
>;
export type GetUserChronicDiseasesQueryResult = Apollo.QueryResult<
  GetUserChronicDiseasesQuery,
  GetUserChronicDiseasesQueryVariables
>;
export const GetUserMedicinesDocument = gql`
  query GetUserMedicines($userId: ID!) {
    getUserMedicines(userId: $userId) {
      id
      user {
        id
      }
      medicine
      dosage
      description
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetUserMedicinesQuery__
 *
 * To run a query within a React component, call `useGetUserMedicinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserMedicinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserMedicinesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserMedicinesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserMedicinesQuery,
    GetUserMedicinesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserMedicinesQuery, GetUserMedicinesQueryVariables>(
    GetUserMedicinesDocument,
    options
  );
}
export function useGetUserMedicinesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserMedicinesQuery,
    GetUserMedicinesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserMedicinesQuery,
    GetUserMedicinesQueryVariables
  >(GetUserMedicinesDocument, options);
}
export function useGetUserMedicinesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserMedicinesQuery,
    GetUserMedicinesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserMedicinesQuery,
    GetUserMedicinesQueryVariables
  >(GetUserMedicinesDocument, options);
}
export type GetUserMedicinesQueryHookResult = ReturnType<
  typeof useGetUserMedicinesQuery
>;
export type GetUserMedicinesLazyQueryHookResult = ReturnType<
  typeof useGetUserMedicinesLazyQuery
>;
export type GetUserMedicinesSuspenseQueryHookResult = ReturnType<
  typeof useGetUserMedicinesSuspenseQuery
>;
export type GetUserMedicinesQueryResult = Apollo.QueryResult<
  GetUserMedicinesQuery,
  GetUserMedicinesQueryVariables
>;
export const GetUserSurgerysDocument = gql`
  query GetUserSurgerys($userId: ID!) {
    getUserSurgerys(userId: $userId) {
      id
      user {
        id
      }
      surgery
      part
      department
      media {
        id
        uri
        name
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetUserSurgerysQuery__
 *
 * To run a query within a React component, call `useGetUserSurgerysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSurgerysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSurgerysQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserSurgerysQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserSurgerysQuery,
    GetUserSurgerysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserSurgerysQuery, GetUserSurgerysQueryVariables>(
    GetUserSurgerysDocument,
    options
  );
}
export function useGetUserSurgerysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserSurgerysQuery,
    GetUserSurgerysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserSurgerysQuery,
    GetUserSurgerysQueryVariables
  >(GetUserSurgerysDocument, options);
}
export function useGetUserSurgerysSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserSurgerysQuery,
    GetUserSurgerysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserSurgerysQuery,
    GetUserSurgerysQueryVariables
  >(GetUserSurgerysDocument, options);
}
export type GetUserSurgerysQueryHookResult = ReturnType<
  typeof useGetUserSurgerysQuery
>;
export type GetUserSurgerysLazyQueryHookResult = ReturnType<
  typeof useGetUserSurgerysLazyQuery
>;
export type GetUserSurgerysSuspenseQueryHookResult = ReturnType<
  typeof useGetUserSurgerysSuspenseQuery
>;
export type GetUserSurgerysQueryResult = Apollo.QueryResult<
  GetUserSurgerysQuery,
  GetUserSurgerysQueryVariables
>;
export const GetUserVaccinesDocument = gql`
  query GetUserVaccines($userId: ID!) {
    getUserVaccines(userId: $userId) {
      id
      user {
        id
      }
      vaccineType {
        id
        language
        name
      }
      level
      lastVaccinatedAt
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetUserVaccinesQuery__
 *
 * To run a query within a React component, call `useGetUserVaccinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserVaccinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserVaccinesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserVaccinesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserVaccinesQuery,
    GetUserVaccinesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserVaccinesQuery, GetUserVaccinesQueryVariables>(
    GetUserVaccinesDocument,
    options
  );
}
export function useGetUserVaccinesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserVaccinesQuery,
    GetUserVaccinesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserVaccinesQuery,
    GetUserVaccinesQueryVariables
  >(GetUserVaccinesDocument, options);
}
export function useGetUserVaccinesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserVaccinesQuery,
    GetUserVaccinesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserVaccinesQuery,
    GetUserVaccinesQueryVariables
  >(GetUserVaccinesDocument, options);
}
export type GetUserVaccinesQueryHookResult = ReturnType<
  typeof useGetUserVaccinesQuery
>;
export type GetUserVaccinesLazyQueryHookResult = ReturnType<
  typeof useGetUserVaccinesLazyQuery
>;
export type GetUserVaccinesSuspenseQueryHookResult = ReturnType<
  typeof useGetUserVaccinesSuspenseQuery
>;
export type GetUserVaccinesQueryResult = Apollo.QueryResult<
  GetUserVaccinesQuery,
  GetUserVaccinesQueryVariables
>;
export const GetUserInsurancesDocument = gql`
  query GetUserInsurances($userId: ID!) {
    getUserInsurances(userId: $userId) {
      id
      user {
        id
      }
      type
      name
      coverage
      memberId
      groupId
      companyNumber
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetUserInsurancesQuery__
 *
 * To run a query within a React component, call `useGetUserInsurancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInsurancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInsurancesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserInsurancesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserInsurancesQuery,
    GetUserInsurancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserInsurancesQuery,
    GetUserInsurancesQueryVariables
  >(GetUserInsurancesDocument, options);
}
export function useGetUserInsurancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserInsurancesQuery,
    GetUserInsurancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserInsurancesQuery,
    GetUserInsurancesQueryVariables
  >(GetUserInsurancesDocument, options);
}
export function useGetUserInsurancesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserInsurancesQuery,
    GetUserInsurancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserInsurancesQuery,
    GetUserInsurancesQueryVariables
  >(GetUserInsurancesDocument, options);
}
export type GetUserInsurancesQueryHookResult = ReturnType<
  typeof useGetUserInsurancesQuery
>;
export type GetUserInsurancesLazyQueryHookResult = ReturnType<
  typeof useGetUserInsurancesLazyQuery
>;
export type GetUserInsurancesSuspenseQueryHookResult = ReturnType<
  typeof useGetUserInsurancesSuspenseQuery
>;
export type GetUserInsurancesQueryResult = Apollo.QueryResult<
  GetUserInsurancesQuery,
  GetUserInsurancesQueryVariables
>;
export const GetUserSubscriptionsAsAdminDocument = gql`
  query GetUserSubscriptionsAsAdmin($first: Int, $after: String) {
    getUserSubscriptionsAsAdmin(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        user {
          id
          email
          name
          gender
          phoneCode
          phone
          nationality {
            id
            name
          }
          languageOptions {
            id
            name
          }
          isSubscriber
        }
        subscriptionPlan {
          id
          name
          finalPrice
          tickets
        }
        from
        to
        nextPayAt
        status
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useGetUserSubscriptionsAsAdminQuery__
 *
 * To run a query within a React component, call `useGetUserSubscriptionsAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSubscriptionsAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSubscriptionsAsAdminQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetUserSubscriptionsAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserSubscriptionsAsAdminQuery,
    GetUserSubscriptionsAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserSubscriptionsAsAdminQuery,
    GetUserSubscriptionsAsAdminQueryVariables
  >(GetUserSubscriptionsAsAdminDocument, options);
}
export function useGetUserSubscriptionsAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserSubscriptionsAsAdminQuery,
    GetUserSubscriptionsAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserSubscriptionsAsAdminQuery,
    GetUserSubscriptionsAsAdminQueryVariables
  >(GetUserSubscriptionsAsAdminDocument, options);
}
export function useGetUserSubscriptionsAsAdminSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserSubscriptionsAsAdminQuery,
    GetUserSubscriptionsAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserSubscriptionsAsAdminQuery,
    GetUserSubscriptionsAsAdminQueryVariables
  >(GetUserSubscriptionsAsAdminDocument, options);
}
export type GetUserSubscriptionsAsAdminQueryHookResult = ReturnType<
  typeof useGetUserSubscriptionsAsAdminQuery
>;
export type GetUserSubscriptionsAsAdminLazyQueryHookResult = ReturnType<
  typeof useGetUserSubscriptionsAsAdminLazyQuery
>;
export type GetUserSubscriptionsAsAdminSuspenseQueryHookResult = ReturnType<
  typeof useGetUserSubscriptionsAsAdminSuspenseQuery
>;
export type GetUserSubscriptionsAsAdminQueryResult = Apollo.QueryResult<
  GetUserSubscriptionsAsAdminQuery,
  GetUserSubscriptionsAsAdminQueryVariables
>;
export const GetUserPassesAsAdminDocument = gql`
  query GetUserPassesAsAdmin($first: Int, $after: String) {
    getUserPassesAsAdmin(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        purchaser {
          id
          email
          name
          gender
          phoneCode
          phone
          nationality {
            id
            name
          }
          languageOptions {
            id
            name
          }
          isSubscriber
        }
        user {
          id
          email
          name
          gender
          phoneCode
          phone
          nationality {
            id
            name
          }
          languageOptions {
            id
            name
          }
          isSubscriber
        }
        pass {
          id
          name
          finalPrice
          tickets
        }
        status
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useGetUserPassesAsAdminQuery__
 *
 * To run a query within a React component, call `useGetUserPassesAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPassesAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPassesAsAdminQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetUserPassesAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserPassesAsAdminQuery,
    GetUserPassesAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserPassesAsAdminQuery,
    GetUserPassesAsAdminQueryVariables
  >(GetUserPassesAsAdminDocument, options);
}
export function useGetUserPassesAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserPassesAsAdminQuery,
    GetUserPassesAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserPassesAsAdminQuery,
    GetUserPassesAsAdminQueryVariables
  >(GetUserPassesAsAdminDocument, options);
}
export function useGetUserPassesAsAdminSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserPassesAsAdminQuery,
    GetUserPassesAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserPassesAsAdminQuery,
    GetUserPassesAsAdminQueryVariables
  >(GetUserPassesAsAdminDocument, options);
}
export type GetUserPassesAsAdminQueryHookResult = ReturnType<
  typeof useGetUserPassesAsAdminQuery
>;
export type GetUserPassesAsAdminLazyQueryHookResult = ReturnType<
  typeof useGetUserPassesAsAdminLazyQuery
>;
export type GetUserPassesAsAdminSuspenseQueryHookResult = ReturnType<
  typeof useGetUserPassesAsAdminSuspenseQuery
>;
export type GetUserPassesAsAdminQueryResult = Apollo.QueryResult<
  GetUserPassesAsAdminQuery,
  GetUserPassesAsAdminQueryVariables
>;
export const GetUserExtraPlansAsAdminDocument = gql`
  query GetUserExtraPlansAsAdmin($first: Int, $after: String) {
    getUserExtraPlansAsAdmin(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        purchaser {
          id
          email
          name
          gender
          phoneCode
          phone
          nationality {
            id
            name
          }
          languageOptions {
            id
            name
          }
          isSubscriber
        }
        extraPlan {
          id
          tickets
          finalPrice
        }
        status
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useGetUserExtraPlansAsAdminQuery__
 *
 * To run a query within a React component, call `useGetUserExtraPlansAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserExtraPlansAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserExtraPlansAsAdminQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetUserExtraPlansAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserExtraPlansAsAdminQuery,
    GetUserExtraPlansAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserExtraPlansAsAdminQuery,
    GetUserExtraPlansAsAdminQueryVariables
  >(GetUserExtraPlansAsAdminDocument, options);
}
export function useGetUserExtraPlansAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserExtraPlansAsAdminQuery,
    GetUserExtraPlansAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserExtraPlansAsAdminQuery,
    GetUserExtraPlansAsAdminQueryVariables
  >(GetUserExtraPlansAsAdminDocument, options);
}
export function useGetUserExtraPlansAsAdminSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserExtraPlansAsAdminQuery,
    GetUserExtraPlansAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserExtraPlansAsAdminQuery,
    GetUserExtraPlansAsAdminQueryVariables
  >(GetUserExtraPlansAsAdminDocument, options);
}
export type GetUserExtraPlansAsAdminQueryHookResult = ReturnType<
  typeof useGetUserExtraPlansAsAdminQuery
>;
export type GetUserExtraPlansAsAdminLazyQueryHookResult = ReturnType<
  typeof useGetUserExtraPlansAsAdminLazyQuery
>;
export type GetUserExtraPlansAsAdminSuspenseQueryHookResult = ReturnType<
  typeof useGetUserExtraPlansAsAdminSuspenseQuery
>;
export type GetUserExtraPlansAsAdminQueryResult = Apollo.QueryResult<
  GetUserExtraPlansAsAdminQuery,
  GetUserExtraPlansAsAdminQueryVariables
>;
