import React from "react";
import { Section } from "../../common/styles/commonStyles";
import { useGetFaQsQuery } from "../../../lib/apollo/graphql/generated";
import Loading from "../../common/components/Loading";
import Grid from "../../common/components/Grid";
import FAQCard from "../components/FAqCard";

function FAQContainer() {
  const { loading, data } = useGetFaQsQuery({
    variables: {
      activeOnly: false,
    },
  });

  if (loading) return <Loading />;

  return (
    <Section>
      <Grid width="100%" gridTemplateColumns="repeat(4, 1fr)" gap={12}>
        <FAQCard faq={null} />

        {data?.getFAQs?.map((faq) => (
          <FAQCard key={faq?.id} faq={faq} />
        ))}
      </Grid>
    </Section>
  );
}

export default FAQContainer;
