import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import {
  Button,
  Head,
  TBody,
  Table,
  Td,
  Th,
} from "../../common/styles/commonStyles";
import { useGetUserSubscriptionsAsAdminQuery } from "../../../lib/apollo/graphql/generated";
import Loading from "../../common/components/Loading";
import FetchMore from "../../common/components/FetchMore";
import dayjs from "dayjs";

function UserSubscriptionList() {
  const { ref: fetchMoreRef } = useInView({
    threshold: 0.2,
    onChange: onEndReached,
  });

  const [fetchingMore, setFetchingMore] = useState(false);

  const { loading, data, fetchMore } = useGetUserSubscriptionsAsAdminQuery({
    variables: {
      first: 20,
    },
  });

  function onEndReached(inView: boolean) {
    if (
      inView &&
      !fetchingMore &&
      data?.getUserSubscriptionsAsAdmin?.pageInfo?.hasNextPage
    ) {
      setFetchingMore(true);

      fetchMore({
        variables: {
          first: 20,
          after: data?.getUserSubscriptionsAsAdmin?.pageInfo?.endCursor,
        },
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  }

  if (loading) return <Loading />;

  return (
    <>
      <Table>
        <Head>
          <tr>
            <Th>ID</Th>
            <Th>구매자명</Th>
            <Th>전화번호</Th>
            <Th>이메일</Th>
            <Th>국적</Th>
            <Th>구입일</Th>
            <Th>상품명</Th>
            <Th>구매가격</Th>
            <Th>구독 상태</Th>
            <Th>구독 시작일</Th>
            <Th>구독 종료일</Th>
          </tr>
        </Head>
        <TBody>
          {data?.getUserSubscriptionsAsAdmin?.edges?.map((userSubscription) => (
            <tr key={userSubscription?.id}>
              <Td>{userSubscription?.id}</Td>
              <Td>{userSubscription?.user?.name}</Td>
              <Td>{userSubscription?.user?.phone}</Td>
              <Td>{userSubscription?.user?.email}</Td>
              <Td>{userSubscription?.user?.nationality?.name}</Td>
              <Td>
                {dayjs(userSubscription?.createdAt).format("YYYY.MM.DD HH:mm")}
              </Td>
              <Td>{userSubscription?.subscriptionPlan?.name}</Td>
              <Td>{userSubscription?.subscriptionPlan?.finalPrice}</Td>
              <Td>{userSubscription?.status}</Td>
              <Td>{dayjs(userSubscription?.from).format("YYYY.MM.DD")}</Td>
              <Td>
                {userSubscription?.to
                  ? dayjs(userSubscription?.to).format("YYYY.MM.DD")
                  : "-"}
              </Td>
            </tr>
          ))}
        </TBody>
      </Table>
      <FetchMore fetchMoreRef={fetchMoreRef} />
    </>
  );
}

export default UserSubscriptionList;
