export function getDataByLanguage(data: Array<any>) {
  if (!data) return null;

  const categorized: { [key: string]: any[] } = {};

  [...data]?.reduce((prev, current) => {
    if (!current) return prev;

    const { language } = current;

    if (!prev[language]) {
      prev[language] = [current];
    } else {
      prev[language].push(current);
    }

    return prev;
  }, categorized);

  return categorized;
}
