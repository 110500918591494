import { useState } from "react";

function useUsersContainer() {
  const [tabIndex, setTabIndex] = useState(0);

  function onTabSelect(index: number) {
    setTabIndex(index);
  }

  return {
    models: {
      tabIndex,
    },
    operations: {
      onTabSelect,
    },
  };
}

export default useUsersContainer;
