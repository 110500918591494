import React, { useState } from "react";
import { Button, Card } from "../../common/styles/commonStyles";
import Input from "../../common/components/Input";
import {
  GetFaQsDocument,
  useUpdateFaqAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

interface FAQCardProps {
  faq?: {
    __typename?: "FAQ";
    id: string;
    question: string;
    answer: string;
    active: boolean;
    order: number;
  } | null;
}

function FAQCard({ faq }: FAQCardProps) {
  const [state, setState] = useState({
    id: faq?.id || "",
    question: faq?.question || "",
    answer: faq?.answer || "",
    active: faq ? faq?.active : true,
    order: faq?.order || 0,
  });

  const [updateFaqAsAdmin, { loading }] = useUpdateFaqAsAdminMutation({
    onCompleted: () => {
      setState({
        id: faq?.id || "",
        question: faq?.question || "",
        answer: faq?.answer || "",
        active: faq ? faq?.active : true,
        order: faq?.order || 0,
      });
    },
    refetchQueries: () => [
      {
        query: GetFaQsDocument,
        variables: {
          activeOnly: false,
        },
      },
    ],
  });

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value, checked } = e.target;

    if (key === "order" && isNaN(Number(value))) {
      return;
    }

    setState((prev) => ({
      ...prev,
      [key]: key === "active" ? checked : value,
    }));
  }

  function onSubmit() {
    const { id, question, answer, active, order } = state;

    updateFaqAsAdmin({
      variables: {
        faqInput: {
          id,
          question,
          answer,
          active,
          order: Number(order),
        },
      },
    });
  }

  const { id, question, answer, active, order } = state;

  const disabled = !question || !answer || loading;

  return (
    <Card>
      {!!faq && <Input label="id" disabled value={id} />}
      <Input
        label="질문"
        placeholder="질문"
        value={question}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "question")
        }
      />
      <Input
        label="답변"
        placeholder="답변"
        value={answer}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "answer")
        }
      />
      <Input
        label="순서"
        placeholder="순서"
        type="number"
        inputMode="numeric"
        value={order}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "order")
        }
      />
      <Input
        label="공개여부"
        placeholder="공개여부"
        type="checkbox"
        checked={active}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "active")
        }
      />
      <Button disabled={disabled} onClick={onSubmit}>
        {faq ? "업데이트" : "추가"}
      </Button>
    </Card>
  );
}

export default FAQCard;
