import React from "react";
import { useParams } from "react-router-dom";
import {
  useGetUserAllergiesQuery,
  useGetUserMedicinesQuery,
  useGetUserVaccinesQuery,
} from "../../../lib/apollo/graphql/generated";
import UserSectionTitle from "./UserSectionTitle";
import Grid from "../../common/components/Grid";
import Input from "../../common/components/Input";
import dayjs from "dayjs";

function UserVaccines() {
  const params = useParams();

  const userId = params?.userId!;

  const { loading, data } = useGetUserVaccinesQuery({
    variables: {
      userId,
    },
  });

  if (loading || !data?.getUserVaccines || !(data?.getUserVaccines?.length > 0))
    return <div />;

  return (
    <div>
      <UserSectionTitle text="Vaccinations" />

      {data?.getUserVaccines?.map((userVaccine) => {
        const { id, vaccineType, level, lastVaccinatedAt } = userVaccine!;

        return (
          <Grid
            key={id}
            width="100%"
            gap={12}
            gridTemplateColumns="repeat(2, 1fr)"
            marginBottom={24}
          >
            <Input label="백신명" value={vaccineType?.name || ""} disabled />
            <Input label="단계" value={level || ""} disabled />
            <Input
              label="최근접종일자"
              value={
                lastVaccinatedAt
                  ? dayjs(lastVaccinatedAt)?.format("YYYY-MM-DD")
                  : ""
              }
              disabled
            />
          </Grid>
        );
      })}
    </div>
  );
}

export default UserVaccines;
