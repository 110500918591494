import React, { useMemo, useState } from "react";
import { Section } from "../../common/styles/commonStyles";
import {
  GetLanguageOptionsDocument,
  useGetLanguageOptionsQuery,
  useUpdateLanguageOptionAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";
import Loading from "../../common/components/Loading";
import { getDataByLanguage } from "../../common/utils/getDataByLanguage";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Grid from "../../common/components/Grid";
import LanguageOptionCard from "../components/LanguageOptionCard";

function LanguageOptionContainer() {
  const [tabIndex, setTabIndex] = useState(0);

  const { loading, data } = useGetLanguageOptionsQuery();

  const [updateLanguageOption] = useUpdateLanguageOptionAsAdminMutation();

  const showingData = useMemo(
    () => getDataByLanguage(data?.getLanguageOptions || []),
    [data?.getLanguageOptions]
  );

  function onTabSelect(index: number) {
    setTabIndex(index);
  }

  function onUpdate(typeInput: any) {
    updateLanguageOption({
      variables: {
        languageOptionInput: typeInput,
      },
      refetchQueries: !typeInput?.id
        ? () => [
            {
              query: GetLanguageOptionsDocument,
            },
          ]
        : undefined,
    });
  }

  if (loading) return <Loading />;

  return (
    <Section>
      <div style={{ marginBottom: 24 }}>
        <a
          href="https://en.wikipedia.org/wiki/IETF_language_tag"
          target="_blank"
        >
          언어 코드 리스트
        </a>
      </div>
      <Tabs tabIndex={tabIndex} onSelect={onTabSelect}>
        <TabList>
          {showingData &&
            !!Object.keys(showingData)?.length &&
            Object.keys(showingData)?.map((key) => <Tab key={key}>{key}</Tab>)}
        </TabList>
        {showingData && !!Object.keys(showingData)?.length ? (
          Object.keys(showingData)?.map((key) => (
            <TabPanel key={key}>
              <Grid width="100%" gridTemplateColumns="repeat(4, 1fr)" gap={12}>
                <LanguageOptionCard option={null} updateFunction={onUpdate} />
                {showingData[key]?.map((option) => (
                  <LanguageOptionCard
                    key={option?.id}
                    option={option}
                    updateFunction={onUpdate}
                  />
                ))}
              </Grid>
            </TabPanel>
          ))
        ) : (
          <TabPanel>
            <LanguageOptionCard option={null} updateFunction={onUpdate} />
          </TabPanel>
        )}
      </Tabs>
    </Section>
  );
}

export default LanguageOptionContainer;
