import React, { useState } from "react";
import {
  Button,
  Section,
  SectionTitle,
} from "../../common/styles/commonStyles";
import {
  useGetManagementQuery,
  useUpdateManagementAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";
import Loading from "../../common/components/Loading";
import Grid from "../../common/components/Grid";
import Input from "../../common/components/Input";

function ManagementContainer() {
  const [state, setState] = useState({
    companyName: "",
    address: "",
    copyright: "",
    email: "",
    ceoName: "",
    registrationNumber: "",
    escroRegistration: "",
    phone: "",
    kakaoAddress: "",
    sessionDuration: 0,
    shopAddress: "",
    paymentWarning: "",
    paymentInformation: "",
  });

  const { loading, data } = useGetManagementQuery({
    onCompleted: (data) => {
      if (data?.getManagement) {
        setState((prev) => ({
          ...prev,
          companyName: data?.getManagement?.companyName || prev?.companyName,
          address: data?.getManagement?.address || prev?.address,
          copyright: data?.getManagement?.copyright || prev?.copyright,
          email: data?.getManagement?.email || prev?.email,
          ceoName: data?.getManagement?.ceoName || prev?.ceoName,
          registrationNumber:
            data?.getManagement?.registrationNumber || prev?.registrationNumber,
          escroRegistration:
            data?.getManagement?.escroRegistration || prev?.escroRegistration,
          phone: data?.getManagement?.phone || prev?.phone,
          kakaoAddress: data?.getManagement?.kakaoAddress || prev?.kakaoAddress,
          sessionDuration:
            data?.getManagement?.sessionDuration || prev?.sessionDuration,
          shopAddress: data?.getManagement?.shopAddress || prev?.shopAddress,
          paymentWarning:
            data?.getManagement?.paymentWarning || prev?.paymentWarning,
          paymentInformation:
            data?.getManagement?.paymentInformation || prev?.paymentInformation,
        }));
      }
    },
  });

  const [updateManagementAsAdmin, { loading: updateLoading }] =
    useUpdateManagementAsAdminMutation();

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value, checked } = e.target;

    if (key === "sessionDuration" && isNaN(Number(value))) {
      return;
    }

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  if (loading) return <Loading />;

  const {
    companyName,
    address,
    copyright,
    email,
    ceoName,
    registrationNumber,
    escroRegistration,
    phone,
    kakaoAddress,
    sessionDuration,
    shopAddress,
    paymentInformation,
    paymentWarning,
  } = state;

  return (
    <Section>
      <Grid gridTemplateColumns="repeat(2, 1fr)" gap={8} marginBottom={24}>
        <Input
          label="회사명"
          value={companyName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "companyName")
          }
        />
        <Input
          label="주소"
          value={address}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "address")
          }
        />
        <Input
          label="대표자명"
          value={ceoName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "ceoName")
          }
        />
        <Input
          label="Email"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "email")
          }
        />
        <Input
          label="사업자등록번호"
          value={registrationNumber}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "registrationNumber")
          }
        />
        <Input
          label="통신판매업 등록번호"
          value={escroRegistration}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "escroRegistration")
          }
        />
        <Input
          label="전화번호"
          value={phone}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "phone")
          }
        />
        <Input
          label="카카오채널주소"
          value={kakaoAddress}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "kakaoAddress")
          }
        />
        <Input
          label="세션 시간(분)"
          value={sessionDuration}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "sessionDuration")
          }
        />
        <Input
          label="건기식 샵 주소"
          value={shopAddress}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "shopAddress")
          }
        />
      </Grid>
      <Button
        disabled={updateLoading}
        onClick={() => {
          updateManagementAsAdmin({
            variables: {
              managementInput: {
                companyName,
                address,
                copyright,
                email,
                ceoName,
                registrationNumber,
                escroRegistration,
                phone,
                kakaoAddress,
                sessionDuration,
                shopAddress,
                paymentInformation,
                paymentWarning,
              },
            },
          });
        }}
      >
        {updateLoading ? "업데이트 중" : "업데이트"}
      </Button>
    </Section>
  );
}

export default ManagementContainer;
