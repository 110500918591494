import React from "react";
import styled, { ThemedComponentProps } from "styled-components";

interface UserSectionTitleProps {
  text: string;
}

function UserSectionTitle({ text }: UserSectionTitleProps) {
  return <Title>{text}</Title>;
}

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.head5,
}));

export default UserSectionTitle;
