import React, { useMemo, useState } from "react";
import {
  GetAllExtraPlansAsAdminDocument,
  useGetAllExtraPlansAsAdminQuery,
  useUpdateExtraPlanAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";
import Loading from "../../common/components/Loading";
import Grid from "../../common/components/Grid";
import { Button, Card } from "../../common/styles/commonStyles";
import Input from "../../common/components/Input";

function ExtraPlanContainer() {
  const { loading, data } = useGetAllExtraPlansAsAdminQuery({
    fetchPolicy: "cache-and-network",
  });

  const [updatePassAsAdmin] = useUpdateExtraPlanAsAdminMutation();

  function onUpdate(typeInput: any) {
    updatePassAsAdmin({
      variables: {
        extraPlanInput: typeInput,
      },
      refetchQueries: !typeInput?.id
        ? () => [
            {
              query: GetAllExtraPlansAsAdminDocument,
            },
          ]
        : undefined,
    });
  }

  if (loading) return <Loading />;

  return (
    <div>
      <Grid width="100%" gridTemplateColumns="repeat(4, 1fr)" gap={12}>
        {data?.getAllExtraPlansAsAdmin &&
        data?.getAllExtraPlansAsAdmin?.length > 0 ? (
          <>
            <ExtraPlanCard extraPlan={null} updateFunction={onUpdate} />
            {data?.getAllExtraPlansAsAdmin?.map((extraPlan) => (
              <ExtraPlanCard
                key={extraPlan?.id}
                extraPlan={extraPlan}
                updateFunction={onUpdate}
              />
            ))}
          </>
        ) : (
          <>
            <ExtraPlanCard extraPlan={null} updateFunction={onUpdate} />
          </>
        )}
      </Grid>
    </div>
  );
}

export default ExtraPlanContainer;

interface ExtraPlanCard {
  extraPlan?: {
    __typename?: "ExtraPlan";
    id: string;
    tickets: number;
    initialPrice: number;
    finalPrice: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  } | null;

  updateFunction?: (typeInput: any) => void;
}

function ExtraPlanCard({ extraPlan, updateFunction }: ExtraPlanCard) {
  const [state, setState] = useState({
    id: extraPlan?.id || "",
    initialPrice: extraPlan?.initialPrice || 0,
    finalPrice: extraPlan?.finalPrice || 0,
    tickets: extraPlan?.tickets || 0,
    active: extraPlan ? extraPlan?.active : true,
  });

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value, checked } = e.target;

    if (
      (key === "tickets" || key === "initialPrice" || key === "finalPrice") &&
      isNaN(Number(value))
    ) {
      return;
    }

    setState((prev) => ({
      ...prev,
      [key]: key === "active" ? checked : value,
    }));
  }

  const { id, initialPrice, finalPrice, tickets, active } = state;

  return (
    <Card>
      {!!extraPlan && <Input label="id" disabled value={id} />}

      <Input
        label="상담건수"
        placeholder="상담건수"
        type="number"
        inputMode="numeric"
        value={tickets}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "tickets")
        }
      />

      <Input
        label="가격"
        placeholder="가격"
        type="number"
        inputMode="numeric"
        value={finalPrice}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "finalPrice")
        }
      />

      <Input
        label="공개여부"
        placeholder="공개여부"
        type="checkbox"
        checked={active}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "active")
        }
      />

      <Button
        disabled={!updateFunction}
        onClick={() => {
          if (!updateFunction) return;
          updateFunction({
            id,
            tickets: Number(tickets),
            initialPrice: Number(finalPrice),
            finalPrice: Number(finalPrice),
            active,
          });

          if (!extraPlan) {
            setState({
              id: "",
              initialPrice: 0,
              finalPrice: 0,
              tickets: 0,
              active: true,
            });
          }
        }}
      >
        {extraPlan ? "업데이트" : "추가"}
      </Button>
    </Card>
  );
}
