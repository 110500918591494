import React from "react";
import styled, { ThemedComponentProps } from "styled-components";

interface InputLabelProps {
  label?: string | number;
}

function InputLabel({ label }: InputLabelProps) {
  return <Text>{label}</Text>;
}

const Text = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.body1,
  color: theme.color.black,
}));

export default InputLabel;
