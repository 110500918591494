import React, { useMemo, useState } from "react";
import {
  GetAllergySymptomsAsAdminDocument,
  useGetAllergySymptomsAsAdminQuery,
  useUpdateAllergySymptomAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";
import Loading from "../../common/components/Loading";
import Grid from "../../common/components/Grid";
import { getDataByLanguage } from "../../common/utils/getDataByLanguage";
import PHROptionCard from "./PHROptionCard";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

function PHRAllergySymptoms() {
  const [tabIndex, setTabIndex] = useState(0);

  const { loading, data } = useGetAllergySymptomsAsAdminQuery({
    fetchPolicy: "cache-and-network",
  });

  const [updateAllergySymptoms] = useUpdateAllergySymptomAsAdminMutation();

  const showingData = useMemo(
    () => getDataByLanguage(data?.getAllergySymptomsAsAdmin || []),
    [data?.getAllergySymptomsAsAdmin]
  );

  function onTabSelect(index: number) {
    setTabIndex(index);
  }

  function onUpdate(typeInput: any) {
    updateAllergySymptoms({
      variables: {
        typeInput,
      },
      refetchQueries: !typeInput?.id
        ? () => [
            {
              query: GetAllergySymptomsAsAdminDocument,
            },
          ]
        : undefined,
    });
  }

  if (loading) return <Loading />;

  return (
    <Tabs tabIndex={tabIndex} onSelect={onTabSelect}>
      <TabList>
        {showingData &&
          !!Object.keys(showingData)?.length &&
          Object.keys(showingData)?.map((key) => <Tab key={key}>{key}</Tab>)}
      </TabList>
      {showingData && !!Object.keys(showingData)?.length ? (
        Object.keys(showingData)?.map((key) => (
          <TabPanel key={key}>
            <Grid width="100%" gridTemplateColumns="repeat(4, 1fr)" gap={12}>
              <PHROptionCard option={null} updateFunction={onUpdate} />
              {showingData[key]?.map((option) => (
                <PHROptionCard
                  key={option?.id}
                  option={option}
                  updateFunction={onUpdate}
                />
              ))}
            </Grid>
          </TabPanel>
        ))
      ) : (
        <TabPanel>
          <PHROptionCard option={null} updateFunction={onUpdate} />
        </TabPanel>
      )}
    </Tabs>
  );
}

export default PHRAllergySymptoms;
