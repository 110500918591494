import React from "react";
import Flex from "../../common/components/Flex";
import Avatar from "../../common/components/Avatar";
import {
  Button,
  Head,
  TBody,
  Table,
  Td,
  Th,
} from "../../common/styles/commonStyles";
import dayjs from "dayjs";
import {
  GetUnpaidSessionsAsAdminDocument,
  PaymentInput,
  PaymentUnitPriceType,
  useAddPaymentsAsAdminMutation,
  useGetPaymentUnitPricesAsAdminQuery,
  useGetUserBankAccountAsAdminQuery,
} from "../../../lib/apollo/graphql/generated";

interface PaymentCardProps {
  userId: string;
  data: any;
  user: any;
  isInterpreter?: boolean;
}

function PaymentCard({
  userId,
  data,
  user,
  isInterpreter = false,
}: PaymentCardProps) {
  const { sessions } = data;

  const { data: bankData } = useGetUserBankAccountAsAdminQuery({
    variables: {
      userId,
    },
  });

  const { loading: unitLoading, data: unitData } =
    useGetPaymentUnitPricesAsAdminQuery();

  const [addPaymentAsAdmin] = useAddPaymentsAsAdminMutation({
    refetchQueries: () => [
      {
        query: GetUnpaidSessionsAsAdminDocument,
      },
    ],
  });

  const count = sessions?.length || 0;

  const koreanUnitPrice = unitData?.getPaymentUnitPricesAsAdmin?.find((price) =>
    price?.language === "ko" && isInterpreter
      ? price?.type === PaymentUnitPriceType.Interpreter
      : price?.type === PaymentUnitPriceType.Instructor
  );

  const total = count * (koreanUnitPrice?.unitPrice || 0);

  function onPaidClick() {
    const paymentInput: PaymentInput = {
      userId,
      bankAccountId: bankData?.getUserBankAccountAsAdmin?.id!,
      currency: "KRW",
      amount: koreanUnitPrice?.unitPrice || 0,
      type: isInterpreter
        ? PaymentUnitPriceType.Interpreter
        : PaymentUnitPriceType.Instructor,
    };

    const sessionIds = [...(sessions || [])]?.map((session) => session?.id);

    paymentInput.sessionIds = sessionIds;

    addPaymentAsAdmin({
      variables: {
        paymentInput,
      },
    });
  }

  return (
    <Flex width="100%" flexDirection="column" marginBottom={12}>
      <Flex
        width="100%"
        padding={12}
        border="1px solid black"
        gap={16}
        alignItems="center"
      >
        <Avatar diameter={40} uri={user?.avatar?.uri} />
        <div>{user?.name}</div>
        <div>
          <div>
            은행명 :{" "}
            {bankData?.getUserBankAccountAsAdmin?.bankName || "기입요망"}
          </div>
          <div>
            계좌번호 :{" "}
            {bankData?.getUserBankAccountAsAdmin?.accountNumber || "기입요망"}
          </div>
        </div>
        <div>
          <div>총 세션 수 : {count}</div>
        </div>
        <div>
          <div>총 정산금액 수 : {total}</div>
        </div>

        <div>
          <div>단가 : {koreanUnitPrice?.unitPrice || "-"}</div>
        </div>
        <Button
          disabled={!bankData?.getUserBankAccountAsAdmin?.id}
          onClick={onPaidClick}
        >
          지급완료
        </Button>
      </Flex>
      <Table>
        <Head>
          <tr>
            <Th>
              <div>신청자</div>
            </Th>
            <Th>
              <div>이용권</div>
            </Th>
            <Th>
              <div>세션 일시</div>
            </Th>
          </tr>
        </Head>
        <TBody>
          {sessions?.map((session: any) => {
            return (
              <tr key={session?.id}>
                <Td>{session?.requester?.name}</Td>
                <Td>{session?.userPass ? "이용권" : "구독자"}</Td>
                <Td>{dayjs(session?.startAt).format("YYYY-MM-DD HH:mm")}</Td>
              </tr>
            );
          })}
        </TBody>
      </Table>
    </Flex>
  );
}

export default PaymentCard;
