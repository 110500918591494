import React, { useState } from "react";
import { Button, Card } from "../../common/styles/commonStyles";
import Input from "../../common/components/Input";

interface LanguageOptionCard {
  option?: {
    id: string;
    language?: string | null;
    name: string;
    order: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  } | null;

  updateFunction?: (typeInput: any) => void;
}

function LanguageOptionCard({ option, updateFunction }: LanguageOptionCard) {
  const [state, setState] = useState({
    id: option?.id || "",
    language: option?.language || "",
    name: option?.name || "",
    order: option?.order || 0,
    active: option ? option?.active : true,
  });

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value, checked } = e.target;

    if (key === "order" && isNaN(Number(value))) {
      return;
    }

    setState((prev) => ({
      ...prev,
      [key]: key === "active" ? checked : value,
    }));
  }

  const { id, language, name, order, active } = state;

  return (
    <Card>
      {!!option && <Input label="id" disabled value={id} />}
      <Input
        label="언어 코드"
        placeholder="데이터 언어 코드"
        value={language}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "language")
        }
      />
      <Input
        label="명칭"
        placeholder="명칭"
        value={name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "name")
        }
      />
      <Input
        label="순서"
        placeholder="순서"
        type="number"
        inputMode="numeric"
        value={order}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "order")
        }
      />
      <Input
        label="공개여부"
        placeholder="공개여부"
        type="checkbox"
        checked={active}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "active")
        }
      />
      <Button
        disabled={!updateFunction}
        onClick={() => {
          if (!updateFunction) return;
          updateFunction({
            id,
            language,
            name,
            active,
            order: Number(order),
          });

          if (!option) {
            setState({
              id: "",
              language: "",
              name: "",
              order: 0,
              active: true,
            });
          }
        }}
      >
        {option ? "업데이트" : "추가"}
      </Button>
    </Card>
  );
}

export default LanguageOptionCard;
