import React, { useState } from "react";
import { Section } from "../../common/styles/commonStyles";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import UserPassList from "../components/UserPassList";
import UserSubscriptionList from "../components/UserSubscriptionList";
import UserExtraPlanList from "../components/UserExtraPlanList";

function UserPurchaseContainer() {
  const [tabIndex, setTabIndex] = useState(0);

  function onTabSelect(index: number) {
    setTabIndex(index);
  }

  return (
    <Section>
      <Tabs tabIndex={tabIndex} onSelect={onTabSelect}>
        <TabList>
          <Tab>단건구매</Tab>
          <Tab>구독권구매</Tab>
          <Tab>추가연장권구매</Tab>
        </TabList>
        <TabPanel>
          <UserPassList />
        </TabPanel>
        <TabPanel>
          <UserSubscriptionList />
        </TabPanel>

        <TabPanel>
          <UserExtraPlanList />
        </TabPanel>
      </Tabs>
    </Section>
  );
}

export default UserPurchaseContainer;
