import styled, { ThemedComponentProps } from "styled-components";

export const Section = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.regular,
}));

export const Card = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  borderRadius: 6,
  padding: theme.spacing.default,
  border: `1px solid ${theme.color.black1}`,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.small,
}));

interface ButtonProps extends ThemedComponentProps {
  disabled?: boolean;
}

export const Button = styled.button<any>(
  ({ theme, disabled }: ButtonProps) => ({
    cursor: "pointer",
    padding: `${theme.spacing.xxSmall2}px ${theme.spacing.small}px`,
    backgroundColor: theme.color.black3,
    ...theme.font.body3,
    color: theme.color.white,
    opacity: disabled ? 0.5 : 1,
    pointerEvents: disabled ? "none" : "auto",

    ":hover": {
      backgroundColor: theme.color.black1,
    },
  })
);

export const SectionTitle = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    ...theme.font.head5,
    fontWeight: "bold",
    marginBottom: theme.spacing.middle,
  })
);

export const GridInput = styled.input<any>(
  ({ theme }: ThemedComponentProps) => ({
    ...theme.font.body2,
    width: "100%",
    padding: theme.spacing.xxSmall2,
  })
);

export const Row = styled.div<any>(() => ({
  display: "flex",
  alignItems: "center",
}));

export const Col = styled.div<any>(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const Input = styled.input<any>(({ theme }: ThemedComponentProps) => ({
  borderBottom: `1px solid ${theme.color.black7}`,
  flex: 1,
  ...theme.font.body1,
}));

export const Table = styled.table<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  height: "100%",
  borderSpacing: 0,
}));

export const Head = styled.thead<any>(() => ({
  padding: 0,
}));

export const Th = styled.th<any>(({ theme }: ThemedComponentProps) => ({
  border: `1px solid ${theme.color.black6}`,
  backgroundColor: theme.color.black8,
  padding: theme.spacing.small,
  textAlign: "left",
}));

export const TBody = styled.tbody<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  height: "100%",
  overflowY: "auto",
  ...theme.font.body2,
}));

export const Td = styled.td<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.small,
  border: `1px solid ${theme.color.black6}`,
}));

export const Tabs = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  gap: theme.spacing.small,
  marginBottom: theme.spacing.default,
}));

interface TabProps extends ThemedComponentProps {
  active?: boolean;
}

export const Tab = styled.button<any>(({ theme, active }: TabProps) => ({
  padding: theme.spacing.small,
  backgroundColor: active ? theme.color.black : theme.color.black3,
  color: theme.color.white,
  ...theme.font.body3,

  ":hover": {
    backgroundColor: theme.color.black5,
  },
}));
