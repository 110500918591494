import React, { ComponentPropsWithRef } from "react";
import styled, { ThemedComponentProps } from "styled-components";
import Flex from "./Flex";
import InputLabel from "./InputLabel";

interface InputProps extends ComponentPropsWithRef<"input"> {
  label?: string | number;
}

function Input({ label, ...rest }: InputProps) {
  return (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      gap={8}
      boxSizing="border-box"
      position="relative"
    >
      {!!label && <InputLabel label={label} />}
      <TextInput
        style={{
          width: "100%",
        }}
        {...rest}
      />
    </Flex>
  );
}

const TextInput = styled.input<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.normal,
  border: `1px solid ${theme.color.black3}`,
  backgroundColor: theme.color.white,
  borderRadius: 6,
  ...theme.font.body1,
}));

export default Input;
