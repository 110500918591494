import React from "react";
import { useParams } from "react-router-dom";
import {
  useGetUserAllergiesQuery,
  useGetUserInsurancesQuery,
  useGetUserMedicinesQuery,
  useGetUserSurgerysQuery,
  useGetUserVaccinesQuery,
} from "../../../lib/apollo/graphql/generated";
import UserSectionTitle from "./UserSectionTitle";
import Grid from "../../common/components/Grid";
import Input from "../../common/components/Input";
import dayjs from "dayjs";

function UserInsurances() {
  const params = useParams();

  const userId = params?.userId!;

  const { loading, data } = useGetUserInsurancesQuery({
    variables: {
      userId,
    },
  });

  if (
    loading ||
    !data?.getUserInsurances ||
    !(data?.getUserInsurances?.length > 0)
  )
    return <div />;

  return (
    <div>
      <UserSectionTitle text="Insurance" />

      {data?.getUserInsurances?.map((userInsurance) => {
        const { id, type, companyNumber, name, coverage, memberId, groupId } =
          userInsurance!;

        return (
          <Grid
            key={id}
            width="100%"
            gap={12}
            gridTemplateColumns="repeat(2, 1fr)"
            marginBottom={24}
          >
            <Input label="보험 유형" value={type || ""} disabled />
            <Input label="보험사" value={name || ""} disabled />
            <Input label="상풍명 및 책임정도" value={coverage || ""} disabled />
            <Input label="MEMBER ID" value={memberId || ""} disabled />
            <Input label="GROUP ID" value={groupId || ""} disabled />
            <Input label="회사번호" value={companyNumber || ""} disabled />
          </Grid>
        );
      })}
    </div>
  );
}

export default UserInsurances;
