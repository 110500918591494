import React from "react";
import { useGetUserChronicDiseasesQuery } from "../../../lib/apollo/graphql/generated";
import { useParams } from "react-router-dom";
import UserSectionTitle from "./UserSectionTitle";
import Grid from "../../common/components/Grid";
import Input from "../../common/components/Input";

function UserChronicDisease() {
  const params = useParams();

  const userId = params?.userId!;

  const { loading, data } = useGetUserChronicDiseasesQuery({
    variables: {
      userId,
    },
  });

  if (
    loading ||
    !data?.getUserChronicDiseases ||
    !(data?.getUserChronicDiseases?.length > 0)
  )
    return <div />;

  return (
    <div>
      <UserSectionTitle text="Chronic Disease" />

      {data?.getUserChronicDiseases?.map((chronicDisease) => {
        const { id, type, etc, medicine, firstAid } = chronicDisease!;

        return (
          <Grid
            key={id}
            width="100%"
            gap={12}
            gridTemplateColumns="repeat(2, 1fr)"
            marginBottom={24}
          >
            <Input label="type" value={type?.name || ""} disabled />
            <Input label="그외기타질환" value={etc || ""} disabled />
            <Input label="의약품" value={medicine || ""} disabled />
            <Input label="긴급조치" value={firstAid || ""} disabled />
          </Grid>
        );
      })}
    </div>
  );
}

export default UserChronicDisease;
