import React from "react";
import { useParams } from "react-router-dom";
import {
  useGetUserAllergiesQuery,
  useGetUserMedicinesQuery,
} from "../../../lib/apollo/graphql/generated";
import UserSectionTitle from "./UserSectionTitle";
import Grid from "../../common/components/Grid";
import Input from "../../common/components/Input";

function UserMedicines() {
  const params = useParams();

  const userId = params?.userId!;

  const { loading, data } = useGetUserMedicinesQuery({
    variables: {
      userId,
    },
  });

  if (
    loading ||
    !data?.getUserMedicines ||
    !(data?.getUserMedicines?.length > 0)
  )
    return <div />;

  return (
    <div>
      <UserSectionTitle text="Medicines" />

      {data?.getUserMedicines?.map((userMedicine) => {
        const { id, medicine, dosage, description } = userMedicine!;

        return (
          <Grid
            key={id}
            width="100%"
            gap={12}
            gridTemplateColumns="repeat(2, 1fr)"
            marginBottom={24}
          >
            <Input label="약품명" value={medicine || ""} disabled />
            <Input label="복용량" value={dosage || ""} disabled />
            <Input label="의약품 상세" value={description || ""} disabled />
          </Grid>
        );
      })}
    </div>
  );
}

export default UserMedicines;
