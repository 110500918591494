import React, { useState } from "react";
import { Section } from "../../common/styles/commonStyles";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import PassContainer from "../components/PassContainer";
import SubscriptionPassContainer from "../components/SubscriptionPassContainer";
import ExtraPlanContainer from "../components/ExtraPlanContainer";

function PurchaseItemsContainer() {
  const [tabIndex, setTabIndex] = useState(0);

  function onTabSelect(index: number) {
    setTabIndex(index);
  }

  return (
    <Section>
      <div style={{ marginBottom: 24 }}>
        <a
          href="https://en.wikipedia.org/wiki/IETF_language_tag"
          target="_blank"
        >
          언어 코드 리스트
        </a>
      </div>
      <Tabs tabIndex={tabIndex} onSelect={onTabSelect}>
        <TabList>
          <Tab>단건상품</Tab>
          <Tab>구독상품</Tab>
          <Tab>추가구매상품</Tab>
        </TabList>
        <TabPanel>
          <PassContainer />
        </TabPanel>
        <TabPanel>
          <SubscriptionPassContainer />
        </TabPanel>

        <TabPanel>
          <ExtraPlanContainer />
        </TabPanel>
      </Tabs>
    </Section>
  );
}

export default PurchaseItemsContainer;
