import React from "react";
import { useParams } from "react-router-dom";
import {
  useGetUserAllergiesQuery,
  useGetUserMedicinesQuery,
  useGetUserSurgerysQuery,
  useGetUserVaccinesQuery,
} from "../../../lib/apollo/graphql/generated";
import UserSectionTitle from "./UserSectionTitle";
import Grid from "../../common/components/Grid";
import Input from "../../common/components/Input";
import dayjs from "dayjs";

function UserSurgeries() {
  const params = useParams();

  const userId = params?.userId!;

  const { loading, data } = useGetUserSurgerysQuery({
    variables: {
      userId,
    },
  });

  if (loading || !data?.getUserSurgerys || !(data?.getUserSurgerys?.length > 0))
    return <div />;

  return (
    <div>
      <UserSectionTitle text="Surgery" />

      {data?.getUserSurgerys?.map((userSurgery) => {
        const { id, surgery, part, department } = userSurgery!;

        return (
          <Grid
            key={id}
            width="100%"
            gap={12}
            gridTemplateColumns="repeat(2, 1fr)"
            marginBottom={24}
          >
            <Input label="수술이력" value={surgery || ""} disabled />
            <Input label="수술 부위" value={part || ""} disabled />
            <Input
              label="수술집행/담당부서"
              value={department || ""}
              disabled
            />
          </Grid>
        );
      })}
    </div>
  );
}

export default UserSurgeries;
