import { ModalProps } from "global";
import React, { useState } from "react";
import ReactModal from "react-modal";
import {
  GetPaymentUnitPricesAsAdminDocument,
  PaymentUnitPriceType,
  useGetPaymentUnitPricesAsAdminQuery,
  useUpdatePaymentUnitPriceAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";
import Loading from "../../common/components/Loading";
import Grid from "../../common/components/Grid";
import { Button, Card } from "../../common/styles/commonStyles";
import Input from "../../common/components/Input";
import InputLabel from "../../common/components/InputLabel";

interface UnitPriceModalProps extends ModalProps {}

function UnitPriceModal({ ...rest }: UnitPriceModalProps) {
  const { loading, data } = useGetPaymentUnitPricesAsAdminQuery();

  return (
    <ReactModal {...rest}>
      {loading ? (
        <Loading />
      ) : (
        <Grid gridTemplateColumns="repeat(4, 1fr)" width="100%" gap={8}>
          <UnitPriceCard price={null} />

          {data?.getPaymentUnitPricesAsAdmin?.map((unitPrice) => (
            <UnitPriceCard key={unitPrice?.id} price={unitPrice} />
          ))}
        </Grid>
      )}
    </ReactModal>
  );
}

export default UnitPriceModal;

interface UnitPriceCardProps {
  price?: {
    __typename?: "PaymentUnitPrice";
    id: string;
    language: string;
    unitPrice: number;
    currency: string;
    type: PaymentUnitPriceType;
  } | null;
}

function UnitPriceCard({ price }: UnitPriceCardProps) {
  const [state, setState] = useState({
    id: price?.id || "",
    language: price?.language || "",
    unitPrice: price?.unitPrice || "",
    currency: price?.currency || "",
    type: price?.type || PaymentUnitPriceType.Instructor,
  });

  const [updatePaymentUnitPriceAsAdmin, { loading }] =
    useUpdatePaymentUnitPriceAsAdminMutation({
      onCompleted: (data) => {
        if (data?.updatePaymentUnitPriceAsAdmin) {
          setState({
            id: price?.id || "",
            language: price?.language || "",
            unitPrice: price?.unitPrice || "",
            currency: price?.currency || "",
            type: price?.type || PaymentUnitPriceType.Instructor,
          });
        }
      },
      refetchQueries: () => [
        {
          query: GetPaymentUnitPricesAsAdminDocument,
        },
      ],
    });

  const { id, language, unitPrice, currency, type } = state;

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value, checked } = e.target;

    if (key === "unitPrice" && isNaN(Number(value))) {
      return;
    }

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onSubmit() {
    updatePaymentUnitPriceAsAdmin({
      variables: {
        paymentUnitPriceInput: {
          id,
          language,
          unitPrice: Number(unitPrice),
          currency,
          type,
        },
      },
    });
  }

  return (
    <Card>
      {!!id && <Input label="id" disabled value={id} />}
      <Input
        label="언어 코드"
        placeholder="언어"
        value={language}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "language")
        }
      />
      <Input
        type="number"
        inputMode="numeric"
        label="단가"
        placeholder="단가"
        value={unitPrice}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "unitPrice")
        }
      />
      <Input
        label="통화단위"
        placeholder="ex) KRW or USD"
        value={currency}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "currency")
        }
      />
      <InputLabel label="유저 타입" />
      <select
        value={type as string}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setState((prev) => ({
            ...prev,
            type: e.target.value as PaymentUnitPriceType,
          }));
        }}
      >
        <option value={PaymentUnitPriceType.Instructor}>지도사</option>
        <option value={PaymentUnitPriceType.Interpreter}>번역가</option>
      </select>

      <Button disabled={loading} onClick={onSubmit}>
        저장
      </Button>
    </Card>
  );
}
