import React from "react";
import {
  Button,
  Section,
  SectionTitle,
} from "../../common/styles/commonStyles";
import { useParams } from "react-router-dom";
import { useGetUserQuery } from "../../../lib/apollo/graphql/generated";
import Loading from "../../common/components/Loading";
import UserProfile from "../components/UserProfile";
import UserPhr from "../components/UserPhr";
import Divider from "../../common/components/Divider";
import { Margin, usePDF } from "react-to-pdf";
import Flex from "../../common/components/Flex";

function UserContainer() {
  const params = useParams();

  const userId = params?.userId!;

  const { loading, data } = useGetUserQuery({
    variables: {
      userId,
    },
  });

  const { toPDF, targetRef } = usePDF({
    filename: `${data?.getUser?.name}_PHR.pdf`,
    method: "save",

    page: {
      margin: Margin.MEDIUM,
      format: "A4",
    },
    overrides: {
      pdf: {
        compress: true,
        encryption: {
          userPassword: data?.getUser?.id?.slice(0, 6),
        },
      },
      canvas: {
        useCORS: true,
      },
    },
  });

  if (loading) return <Loading />;

  return (
    <Section>
      <Flex alignItems="center" justifyContent="space-between">
        <SectionTitle>
          {data?.getUser?.name}_pw:{data?.getUser?.id?.slice(0, 6)}
        </SectionTitle>
        <Button onClick={toPDF}>PDF 다운로드</Button>
      </Flex>
      <div ref={targetRef}>
        <UserProfile user={data?.getUser!} />
        <div style={{ height: 20 }} />
        <UserPhr />
      </div>
    </Section>
  );
}

export default UserContainer;
