import React from "react";
import {
  BloodType,
  Gender,
  UserType,
} from "../../../lib/apollo/graphql/generated";
import { SectionTitle } from "../../common/styles/commonStyles";
import Divider from "../../common/components/Divider";
import Flex from "../../common/components/Flex";
import UserSectionTitle from "./UserSectionTitle";
import Grid from "../../common/components/Grid";
import Input from "../../common/components/Input";
import dayjs from "dayjs";

interface UserProfileProps {
  user: {
    __typename?: "User";
    id: string;
    type: UserType;
    email?: string | null;
    name?: string | null;
    gender?: Gender | null;
    phoneCode?: string | null;
    phone?: string | null;
    dateOfBirth?: any | null;
    provider?: string | null;
    resignReason?: string | null;
    isSubscriber?: boolean | null;
    usuableTickets: number;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    avatar?: { __typename?: "Media"; id: string; uri: string } | null;
    nationality?: {
      __typename?: "Nationality";
      id: string;
      name: string;
    } | null;
    profile?: {
      __typename?: "Profile";
      id: string;
      weight?: number | null;
      height?: number | null;
      bloodType?: BloodType | null;
      occupation?: string | null;
      companyName?: string | null;
      emergencyCallerName?: string | null;
      emergencyCallerPhoneCode?: string | null;
      emergencyCallerPhone?: string | null;
      emergencyCallerBloodType?: BloodType | null;
      emergencyCallerRelation?: string | null;
      emergencyCallerAddress?: string | null;
      createdAt: any;
      updatedAt: any;
      user: { __typename?: "User"; id: string };
      emergencyCallerNationality?: {
        __typename?: "Nationality";
        id: string;
        name: string;
      } | null;
    } | null;
    instructor?: {
      __typename?: "Instructor";
      id: string;
      introduction?: string | null;
      job?: string | null;
      qualificationNumber?: string | null;
      online: boolean;
      createdAt: any;
      updatedAt: any;
      user: { __typename?: "User"; id: string };
      educations?: Array<{
        __typename?: "Education";
        id: string;
        school?: string | null;
        major?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
      experiences?: Array<{
        __typename?: "Experience";
        id: string;
        place?: string | null;
        job?: string | null;
        duration?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null;
    interpreter?: {
      __typename?: "Interpreter";
      id: string;
      introduction?: string | null;
      visaNumber?: string | null;
      passportNumber?: string | null;
      online: boolean;
      createdAt: any;
      updatedAt: any;
      user: { __typename?: "User"; id: string };
      educations?: Array<{
        __typename?: "Education";
        id: string;
        school?: string | null;
        major?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
      experiences?: Array<{
        __typename?: "Experience";
        id: string;
        place?: string | null;
        job?: string | null;
        duration?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null;
    languageOptions?: Array<{
      __typename?: "LanguageOption";
      id: string;
      language: string;
      name: string;
      active: boolean;
    } | null> | null;
  };
}

function UserProfile({ user }: UserProfileProps) {
  const {
    name,
    gender,
    phoneCode,
    phone,
    dateOfBirth,
    avatar,
    nationality,
    profile,
  } = user;

  return (
    <div>
      <SectionTitle>유저 프로필</SectionTitle>
      <Divider size={1} full />

      <Flex flexDirection="column" width="100%" gap={12} marginTop={24}>
        <UserSectionTitle text="기본정보" />

        <Grid width="100%" gap={12} gridTemplateColumns="repeat(2, 1fr)">
          <Input label="이름" value={name || ""} disabled />
          <Input label="성별" value={gender || ""} disabled />
          <Input
            label="전화번호"
            value={`${phoneCode || ""}${phone || ""}`}
            disabled
          />
          <Input
            label="생년월일"
            value={dateOfBirth ? dayjs(dateOfBirth).format("YYYY.MM.DD") : ""}
            disabled
          />
          <Input label="국적" value={nationality?.name || ""} disabled />
        </Grid>
      </Flex>

      <Flex flexDirection="column" width="100%" gap={12} marginTop={24}>
        <UserSectionTitle text="추가정보" />

        <Grid width="100%" gap={12} gridTemplateColumns="repeat(2, 1fr)">
          <Input label="몸무게" value={profile?.weight || ""} disabled />
          <Input label="키" value={profile?.height || ""} disabled />
          <Input label="혈액형" value={profile?.bloodType || ""} disabled />
          <Input label="직업" value={profile?.occupation || ""} disabled />
          <Input label="회사명" value={profile?.companyName || ""} disabled />
        </Grid>
      </Flex>

      <Flex flexDirection="column" width="100%" gap={12} marginTop={24}>
        <UserSectionTitle text="비상연락정보" />

        <Grid width="100%" gap={12} gridTemplateColumns="repeat(2, 1fr)">
          <Input
            label="비상연락자명"
            value={profile?.emergencyCallerName || ""}
            disabled
          />
          <Input
            label="비상연락자전화번호"
            value={`${profile?.emergencyCallerPhoneCode || ""}${
              profile?.emergencyCallerPhone || ""
            }`}
            disabled
          />
          <Input
            label="비상연락자혈액형"
            value={profile?.emergencyCallerBloodType || ""}
            disabled
          />
          <Input
            label="비상연락자관계"
            value={profile?.emergencyCallerRelation || ""}
            disabled
          />
        </Grid>
      </Flex>
    </div>
  );
}

export default UserProfile;
