import { ReactElement } from "react";
import MainContainer from "../../../modules/main/container/MainContainer";
import UsersContainer from "../../../modules/users/container/UsersContainer";
import PHROptionsContainer from "../../../modules/phrOptions/container/PHROptionsContainer";
import LanguageOptionContainer from "../../../modules/languageOption/container/LanguageOptionContainer";
import PurchaseItemsContainer from "../../../modules/purchaseItems/container/PurchaseItemsContainer";
import ManagementContainer from "../../../modules/management/container/ManagementContainer";
import UserPurchaseContainer from "../../../modules/userPurchase/container/UserPurchaseContainer";
import PaymentContainer from "../../../modules/payment/container/PaymentContainer";
import UserContainer from "../../../modules/user/container/UserContainer";
import FAQContainer from "../../../modules/faq/container/FAQContainer";

export type CustomRoute = {
  path: string;
  element: ReactElement;
  name?: string;
  children?: CustomRoute[];
  hideMenu?: boolean;
};

export const PATH_NAMES: { [key: string]: CustomRoute } = {
  HOME: {
    path: "/",
    name: "홈",
    element: <MainContainer />,
  },

  USERS: {
    path: "/users",
    name: "유저목록",
    element: <UsersContainer />,
  },

  USER: {
    path: "/user/:userId",
    name: "유저 정보",
    element: <UserContainer />,
    hideMenu: true,
  },

  USER_PURCHASE: {
    path: "/user_purchase",
    name: "구매목록",
    element: <UserPurchaseContainer />,
  },

  PAYMENT: {
    path: "/payment",
    name: "정산 관리",
    element: <PaymentContainer />,
  },

  PHR_OPTIONS: {
    path: "/phr_options",
    name: "PHR 옵션관리",
    element: <PHROptionsContainer />,
  },

  LANGUAGE_OPTIONS: {
    path: "/language_options",
    name: "언어 관리",
    element: <LanguageOptionContainer />,
  },

  PURCHASE_ITEMS: {
    path: "/purchase_items",
    name: "상품 관리",
    element: <PurchaseItemsContainer />,
  },

  MANAGEMENT: {
    path: "/management",
    name: "기본정보관리",
    element: <ManagementContainer />,
  },

  FAQ: {
    path: "/faq",
    name: "FAQ 관리",
    element: <FAQContainer />,
  },
};
