import React from "react";
import { Section } from "../../common/styles/commonStyles";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import useUsersContainer from "./UsersContainer.hook";
import UserList from "../components/UserList";
import InstructorList from "../components/InstructorList";
import InterpreterList from "../components/InterpreterList";

function UsersContainer() {
  const {
    models: { tabIndex },
    operations: { onTabSelect },
  } = useUsersContainer();

  return (
    <Section>
      <Tabs tabIndex={tabIndex} onSelect={onTabSelect}>
        <TabList>
          <Tab>전체</Tab>
          <Tab>지도사</Tab>
          <Tab>번역가</Tab>
        </TabList>
        <TabPanel>
          <UserList />
        </TabPanel>
        <TabPanel>
          <InstructorList />
        </TabPanel>
        <TabPanel>
          <InterpreterList />
        </TabPanel>
      </Tabs>
    </Section>
  );
}

export default UsersContainer;
