import React from "react";
import { SectionTitle } from "../../common/styles/commonStyles";
import Divider from "../../common/components/Divider";
import UserChronicDisease from "./UserChronicDisease";
import Flex from "../../common/components/Flex";
import UserAllergies from "./UserAllergies";
import UserMedicines from "./UserMedicines";
import UserVaccines from "./UserVaccines";
import UserSurgeries from "./UserSurgeries";
import UserInsurances from "./UserInsurances";

function UserPhr() {
  return (
    <div>
      <SectionTitle>PHR</SectionTitle>
      <Divider size={1} full />
      <Flex flexDirection="column" width="100%" gap={24}>
        <UserChronicDisease />
        <UserAllergies />
        <UserMedicines />
        <UserVaccines />
        <UserSurgeries />
        <UserInsurances />
      </Flex>
    </div>
  );
}

export default UserPhr;
