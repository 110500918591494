import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import { useGetAllInstructorsAsAdminQuery } from "../../../lib/apollo/graphql/generated";
import Loading from "../../common/components/Loading";
import {
  Button,
  Head,
  TBody,
  Table,
  Td,
  Th,
} from "../../common/styles/commonStyles";
import Avatar from "../../common/components/Avatar";
import dayjs from "dayjs";
import FetchMore from "../../common/components/FetchMore";
import { useNavigate } from "react-router-dom";

function InstructorList() {
  const navigate = useNavigate();

  const { ref: fetchMoreRef } = useInView({
    threshold: 0.2,
    onChange: onEndReached,
  });

  const [fetchingMore, setFetchingMore] = useState(false);

  const { loading, data, fetchMore } = useGetAllInstructorsAsAdminQuery({
    variables: {
      first: 20,
    },
  });

  function onEndReached(inView: boolean) {
    if (
      inView &&
      !fetchingMore &&
      data?.getAllInstructorsAsAdmin?.pageInfo?.hasNextPage
    ) {
      setFetchingMore(true);

      fetchMore({
        variables: {
          first: 20,
          after: data?.getAllInstructorsAsAdmin?.pageInfo?.endCursor,
        },
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  }

  if (loading) return <Loading />;

  return (
    <>
      <Table>
        <Head>
          <tr>
            <Th>ID</Th>
            <Th>프사</Th>
            <Th>전화번호</Th>

            <Th>이메일</Th>
            <Th>이름</Th>
            <Th>생년월일</Th>
            <Th>성별</Th>
            <Th>국적</Th>
            <Th>직업</Th>
            <Th>가입일</Th>
            <Th>상세정보</Th>
          </tr>
        </Head>
        <TBody>
          {data?.getAllInstructorsAsAdmin?.edges?.map((instructor) => (
            <tr key={instructor?.id}>
              <Td>{instructor?.id}</Td>
              <Td>
                <Avatar diameter={50} uri={instructor?.user?.avatar?.uri} />
              </Td>
              <Td>{instructor?.user?.phone}</Td>
              <Td>{instructor?.user?.email}</Td>
              <Td>{instructor?.user?.name}</Td>
              <Td>{instructor?.user?.dateOfBirth}</Td>
              <Td>{instructor?.user?.gender}</Td>
              <Td>{instructor?.user?.nationality?.name || ""}</Td>
              <Td>{instructor?.job}</Td>
              <Td>{dayjs(instructor?.createdAt).format("YYYY-MM-DD")}</Td>
              <Td>
                <Button
                  onClick={() => {
                    navigate("/user/" + instructor?.id);
                  }}
                >
                  자세히
                </Button>
              </Td>
            </tr>
          ))}
        </TBody>
      </Table>
      <FetchMore fetchMoreRef={fetchMoreRef} />
    </>
  );
}

export default InstructorList;
