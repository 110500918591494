import React, { useState } from "react";
import {
  Button,
  Head,
  Section,
  SectionTitle,
  TBody,
  Table,
  Td,
  Th,
} from "../../common/styles/commonStyles";
import Flex from "../../common/components/Flex";
import UnitPriceModal from "../components/UnitPriceModal";
import {
  useGetInterpreterUnpaidSessionAsAdminQuery,
  useGetPaymentUnitPricesAsAdminQuery,
  useGetUnpaidSessionsAsAdminQuery,
} from "../../../lib/apollo/graphql/generated";
import Loading from "../../common/components/Loading";
import Avatar from "../../common/components/Avatar";
import dayjs from "dayjs";
import PaymentCard from "../components/PaymentCard";

function PaymentContainer() {
  const [showModal, setShowModal] = useState(false);

  const { loading, data } = useGetUnpaidSessionsAsAdminQuery();

  const { loading: interpreterLoading, data: interpreter } =
    useGetInterpreterUnpaidSessionAsAdminQuery();

  function toggleModal(show: boolean) {
    setShowModal(show);
  }

  if (loading || interpreterLoading) return <Loading />;

  function getInstructorData(data: any) {
    if (!data) return null;

    const obj: any = {};

    [...data].reduce((prev: any, cur: any) => {
      const { instructor } = cur;

      if (!instructor) return prev;

      if (prev[instructor?.id]) {
        prev[instructor?.id].sessions.push(cur);
      } else {
        prev[instructor?.id] = {
          instructor,
          sessions: [cur],
        };
      }

      return prev;
    }, obj);

    return obj;
  }

  function getInterpreterData(data: any) {
    if (!data) return null;

    const obj: any = {};

    [...data].reduce((prev: any, cur: any) => {
      const { interpreter } = cur;

      if (!interpreter) return prev;

      if (prev[interpreter?.id]) {
        prev[interpreter?.id].sessions.push(cur);
      } else {
        prev[interpreter?.id] = {
          interpreter,
          sessions: [cur],
        };
      }

      return prev;
    }, obj);

    return obj;
  }

  const instructorData = getInstructorData(data?.getUnpaidSessionsAsAdmin);
  const interpreterData = getInterpreterData(
    interpreter?.getInterpreterUnpaidSessionAsAdmin
  );

  return (
    <>
      <Section>
        <Flex width="100%" justifyContent="flex-end" marginBottom={24}>
          <Button onClick={() => toggleModal(!showModal)}>단가 관리</Button>
        </Flex>
        <div style={{ marginBottom: 24 }}>
          <SectionTitle>지도사</SectionTitle>
          {!!instructorData &&
            Object.keys(instructorData)?.map((key: any) => {
              const { sessions } = instructorData[key];

              const { user } =
                instructorData[key as keyof typeof instructorData]?.instructor;

              return (
                <PaymentCard
                  key={key}
                  userId={key}
                  data={instructorData[key]}
                  user={user}
                />
              );
            })}
        </div>
        <div style={{ marginBottom: 24 }}>
          <SectionTitle>번역가</SectionTitle>
          {!!interpreterData &&
            Object.keys(interpreterData)?.map((key: any) => {
              const { sessions } = interpreterData[key];

              const { user } =
                interpreterData[key as keyof typeof interpreterData]
                  ?.interpreter;

              return (
                <PaymentCard
                  key={key}
                  userId={key}
                  data={interpreterData[key]}
                  user={user}
                  isInterpreter
                />
              );
            })}
        </div>
      </Section>
      {showModal && (
        <UnitPriceModal
          isOpen={showModal}
          onRequestClose={() => toggleModal(false)}
        />
      )}
    </>
  );
}

export default PaymentContainer;
