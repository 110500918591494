import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import {
  Button,
  Head,
  TBody,
  Table,
  Td,
  Th,
} from "../../common/styles/commonStyles";
import { useGetUserPassesAsAdminQuery } from "../../../lib/apollo/graphql/generated";
import Loading from "../../common/components/Loading";
import FetchMore from "../../common/components/FetchMore";
import dayjs from "dayjs";

function UserPassList() {
  const { ref: fetchMoreRef } = useInView({
    threshold: 0.2,
    onChange: onEndReached,
  });

  const [fetchingMore, setFetchingMore] = useState(false);

  const { loading, data, fetchMore } = useGetUserPassesAsAdminQuery({
    variables: {
      first: 20,
    },
  });

  function onEndReached(inView: boolean) {
    if (
      inView &&
      !fetchingMore &&
      data?.getUserPassesAsAdmin?.pageInfo?.hasNextPage
    ) {
      setFetchingMore(true);

      fetchMore({
        variables: {
          first: 20,
          after: data?.getUserPassesAsAdmin?.pageInfo?.endCursor,
        },
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  }

  if (loading) return <Loading />;

  return (
    <>
      <Table>
        <Head>
          <tr>
            <Th>ID</Th>
            <Th>구매자명</Th>
            <Th>전화번호</Th>
            <Th>이메일</Th>
            <Th>국적</Th>
            <Th>구입일</Th>
            <Th>상품명</Th>
            <Th>구매가격</Th>
            <Th>선물여부</Th>
            <Th>선물수령자명</Th>
            <Th>선물수령자전화번호</Th>
            <Th>선물수령자이메일</Th>
          </tr>
        </Head>
        <TBody>
          {data?.getUserPassesAsAdmin?.edges?.map((userPass) => (
            <tr key={userPass?.id}>
              <Td>{userPass?.id}</Td>
              <Td>{userPass?.purchaser?.name}</Td>
              <Td>{userPass?.purchaser?.phone}</Td>
              <Td>{userPass?.purchaser?.email}</Td>
              <Td>{userPass?.purchaser?.nationality?.name}</Td>
              <Td>{dayjs(userPass?.createdAt).format("YYYY.MM.DD HH:mm")}</Td>
              <Td>{userPass?.pass?.name}</Td>
              <Td>{userPass?.pass?.finalPrice}</Td>
              <Td>
                {userPass?.purchaser?.id !== userPass?.user?.id ? "O" : "X"}
              </Td>
              <Td>
                {userPass?.purchaser?.id !== userPass?.user?.id
                  ? userPass?.user?.name
                  : "-"}
              </Td>
              <Td>
                {userPass?.purchaser?.id !== userPass?.user?.id
                  ? userPass?.user?.phone
                  : "-"}
              </Td>
              <Td>
                {userPass?.purchaser?.id !== userPass?.user?.id
                  ? userPass?.user?.email
                  : "-"}
              </Td>
            </tr>
          ))}
        </TBody>
      </Table>
      <FetchMore fetchMoreRef={fetchMoreRef} />
    </>
  );
}

export default UserPassList;
