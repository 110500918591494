import React from "react";
import { useParams } from "react-router-dom";
import { useGetUserAllergiesQuery } from "../../../lib/apollo/graphql/generated";
import UserSectionTitle from "./UserSectionTitle";
import Grid from "../../common/components/Grid";
import Input from "../../common/components/Input";

function UserAllergies() {
  const params = useParams();

  const userId = params?.userId!;

  const { loading, data } = useGetUserAllergiesQuery({
    variables: {
      userId,
    },
  });

  if (
    loading ||
    !data?.getUserAllergies ||
    !(data?.getUserAllergies?.length > 0)
  )
    return <div />;

  return (
    <div>
      <UserSectionTitle text="Allergies" />

      {data?.getUserAllergies?.map((userAllergy) => {
        const { id, type, symptoms, firstAid, summary } = userAllergy!;

        const symtomNames = [...(symptoms || [])]?.map(
          (symptom) => symptom?.name || ""
        );
        const symtomText = symtomNames.join(", ");

        return (
          <Grid
            key={id}
            width="100%"
            gap={12}
            gridTemplateColumns="repeat(2, 1fr)"
            marginBottom={24}
          >
            <Input label="type" value={type?.name || ""} disabled />
            <Input label="증상" value={symtomText || ""} disabled />
            <Input label="질환내용 및 복용약" value={summary || ""} disabled />
            <Input label="긴급조치" value={firstAid || ""} disabled />
          </Grid>
        );
      })}
    </div>
  );
}

export default UserAllergies;
